import React from "react";
import {
  clearSessionObject,
  getSessionObject,
  setSessionObject,
} from "../../utils/helpers";
import { V0alpha2Api } from "@ory/kratos-client";
import { Configuration } from "@ory/client";

export const CurrentUser = React.createContext(undefined);

export const sessionControls = {};

export const currentUser = (Component) => {
  return (props) => (
    <CurrentUser.Consumer>
      {({ currentUser, setCurrentUser, clearCurrentUser }) => (
        <Component
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
          clearCurrentUser={clearCurrentUser}
          {...props}
        />
      )}
    </CurrentUser.Consumer>
  );
};

export default class SessionContext extends React.Component {
  state = {
    currentUser: getSessionObject(),
  };

  componentDidMount() {
    sessionControls.logout = this.clearCurrentUser;
    clearSessionObject();
    console.log("*****");
    console.log(
      "process.env.REACT_APP_ORY_URL-test3",
      process.env.REACT_APP_ORY_URL
    );
    const ory = new V0alpha2Api(
      new Configuration({
        basePath: process.env.REACT_APP_ORY_URL,
        baseOptions: {
          withCredentials: true,
        },
      })
    );
    ory
      .toSession()
      .then(({ data }) => {
        console.log("***data", data);
        console.log("****sessionUser", data?.identity?.traits);
        this.setCurrentUser(
          data?.identity?.id,
          data?.identity?.id,
          data?.identity?.traits.email
        );
      })
      .catch((err) => {
        switch (err.response?.status) {
          case 403:
          case 422:
          // TODO: Implement 2FA
          // return navigate("/hc20/auth?aal=aal2");

          case 401:
            // setHasSession(false);
            return;
        }

        console.error(err);
      });
  }

  render() {
    return (
      <CurrentUser.Provider
        value={{
          currentUser: this.state.currentUser,
          setCurrentUser: this.setCurrentUser,
          clearCurrentUser: this.clearCurrentUser,
        }}
      >
        {this.props.children}
      </CurrentUser.Provider>
    );
  }

  setCurrentUser = (customer = "", accountId, emailId) => {
    if (customer === null) {
      customer = "";
    }
    setSessionObject(customer, accountId, emailId);
    this.setState({
      currentUser: getSessionObject(),
    });
  };

  clearCurrentUser = () => {
    clearSessionObject();
    this.setState({ currentUser: undefined });
  };
}
