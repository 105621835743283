/* eslint-disable no-useless-escape */
import { sessionControls } from "../components/partials/SessionContext";
import { API_URL } from "../constants";
import { getSessionObject } from "../utils/helpers";
import { customFetch } from "../utils/webhooks";

export const checkCoreConnection = async (body) => {
  const dto = await customFetch(`${API_URL}/api/core/checkconn/`, "POST", body);
  if (dto.resultCode === "SUCCESS") {
    return dto.resultData;
  } else {
    return false;
  }
};

export const queryProcessingIn = async (
  queryString,
  optionKey,
  requestType,
  subQueryText,
  requestSpec,
  sessionGuid
) => {
  const jsonTestRegex = /[^,:{}\[\]0-9.\-+Eaeflnr-u \n\r\t]/;

  const body = {
    mainQueryText: queryString,
    subQueryText: subQueryText,
    /* sql: process directly from druid*/
    RequestType: requestType, //'ProcessQuery ProcessNLQ',
    OptionKey: jsonTestRegex.test(optionKey)
      ? JSON.parse(optionKey)
      : optionKey,
    RequestSpec: requestSpec,
    SessionGuid: sessionGuid,
    SessionObj: getSessionObject(),
  };

  const dto = await customFetch(
    `${API_URL}/api/core/queryProcessingIn/`,
    "POST",
    body
  );
  if (dto.resultCode === "SUCCESS") {
    return dto.resultData;
  } else {
    if (dto.resultCode === "SESSION_EXPIRED") {
      sessionControls.logout();
    }
    throw new Error("Error occured while queryProcessingIn");
  }
};

export const logMeIn = async (
  queryString,
  optionKey,
  requestType,
  subQueryText,
  requestSpec
) => {
  const jsonTestRegex = /[^,:{}\[\]0-9.\-+Eaeflnr-u \n\r\t]/;

  const body = {
    mainQueryText: queryString,
    subQueryText: subQueryText,
    RequestType: requestType,
    OptionKey: jsonTestRegex.test(optionKey)
      ? JSON.parse(optionKey)
      : optionKey,
    RequestSpec: requestSpec,
  };

  const dto = await customFetch(`${API_URL}/api/core/logmein/`, "POST", body);
  if (dto.resultCode === "SUCCESS") {
    return dto.resultData;
  } else {
    throw new Error(dto.resultMessage);
  }
};
