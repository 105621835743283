import set from "lodash/set";
import { generateQueryProsKey } from "../../utils/helpers";
import {
  CORECONNECTION_API_FAILED,
  CORECONNECTION_API_FETCH,
  CORECONNECTION_API_SUCCESS,
  LOGIN_API_FAILED,
  LOGIN_API_FETCH,
  LOGIN_API_SUCCESS,
  PROSSQUERIN_API_FAILED,
  PROSSQUERIN_API_FETCH,
  PROSSQUERIN_API_SUCCESS,
} from "../actions/coreAPI";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case CORECONNECTION_API_FETCH: {
      return {
        ...state,
        checkCoreConnection: true,
      };
    }
    case CORECONNECTION_API_FAILED:
    case CORECONNECTION_API_SUCCESS: {
      return {
        ...state,
        checkCoreConnection: false,
      };
    }
    case LOGIN_API_FETCH: {
      const key = `login.${generateQueryProsKey(
        action.payload.queryString,
        action.payload.optionKey,
        action.payload.requestType,
        action.payload.subQueryText,
        action.payload.requestSpec
      )}`;

      return {
        ...set(state, key, true),
      };
    }
    case LOGIN_API_FAILED:
    case LOGIN_API_SUCCESS: {
      const key = `login.${generateQueryProsKey(
        action.payload.queryString,
        action.payload.optionKey,
        action.payload.requestType,
        action.payload.subQueryText,
        action.payload.requestSpec
      )}`;
      return {
        ...set(state, key, false),
      };
    }
    case PROSSQUERIN_API_FETCH: {
      const key = `processingQueryIn.${generateQueryProsKey(
        action.payload.queryString,
        action.payload.optionKey,
        action.payload.requestType,
        action.payload.subQueryText,
        action.payload.requestSpec,
        action.payload.sessionGuid
      )}`;

      return {
        ...set(state, key, true),
      };
    }
    case PROSSQUERIN_API_FAILED:
    case PROSSQUERIN_API_SUCCESS: {
      const key = `processingQueryIn.${generateQueryProsKey(
        action.payload.queryString,
        action.payload.optionKey,
        action.payload.requestType,
        action.payload.subQueryText,
        action.payload.requestSpec,
        action.payload.sessionGuid
      )}`;
      return {
        ...set(state, key, false),
      };
    }
    default: {
      return state;
    }
  }
};
