export const customFetch = async (url, method, body) => {
  const resp = await fetch(url, {
    mode: "cors",
    method,
    json: true,
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    }),
    body: JSON.stringify(body),
    credentials: "include",
  });

  if (resp.status >= 200 && resp.status < 300) {
    return resp.json();
  } else {
    const error = new Error(`HTTP Error ${resp.statusText}`);
    error.status = resp.statusText;
    error.response = resp;
    throw error;
  }
};

export const processingQueryGenerator = (laneId) => {
  return {
    fields: {
      field_clause: "NONE",
      field_list: [
        "ACTUAL_DEPARTURE_DATETIME",
        "JOURNEY_DISTANCE_TRAVELLED_MILES",
        "JOURNEY_MODES",
        "TRANSPORTER_NAME",
      ],
    },
    where: [
      {
        key: "LANE_UID",
        condition: "equals",
        value: laneId,
      },
    ],
  };
};
