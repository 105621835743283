import set from "lodash/set";
import { generateQueryProsKey, getSessionObject } from "../../utils/helpers";
import {
  CORECONNECTION_API_SUCCESS,
  LOGIN_API_SUCCESS,
  PROSSQUERIN_API_SUCCESS,
} from "../actions/coreAPI";

const initialState = {
  user: getSessionObject(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CORECONNECTION_API_SUCCESS: {
      return {
        ...state,
        coreConnected: action.payload,
      };
    }
    case PROSSQUERIN_API_SUCCESS: {
      const key = `processingQueryIn.${generateQueryProsKey(
        action.payload.queryString,
        action.payload.optionKey,
        action.payload.requestType,
        action.payload.subQueryText,
        action.payload.requestSpec,
        action.payload.sessionGuid
      )}`;

      return {
        ...set(state, key, action.payload.data),
      };
    }
    case LOGIN_API_SUCCESS: {
      const key = `login.${generateQueryProsKey(
        action.payload.queryString,
        action.payload.optionKey,
        action.payload.requestType,
        action.payload.subQueryText,
        action.payload.requestSpec
      )}`;
      return {
        ...set(state, key, action.payload.data),
      };
    }
    default: {
      return state;
    }
  }
};
