export default function reducer(state, action) {
  switch (action.type) {
    case "FETCH_MENU":
      let payload = action.payload;
      if (payload.length < 1) {
        payload = state.menus;
      }
      let menus = state.menus.concat(payload);
      return { ...state, menus };
    case "ADD_MENU":
      return {};

    case "TOGGLE_THEME":
      return { ...state, isDark: action.payload };
    case "TOGGLE_SIDEBAR":
      localStorage.setItem("isSideBarHidden", action.payload);
      return { ...state, isSideBarHidden: action.payload };
    case "SET_DATERANGE":
      return {
        ...state,
        selectedDateRange: { start: action.payload[0], end: action.payload[1] },
      };
    case "TOGGLE_FULLSCREEN_MODE":
      return {
        ...state,
        isFullscreen: !state.isFullscreen,
      };
    // case value:

    //     break;

    default:
      return state;
  }
}
