import React from "react";
import { Col, ListGroup, ListGroupItem, Row } from "reactstrap";

export default class extends React.Component {
  renderApiInfo = () => {
    if (this.props.coreConnected && this.props.coreConnected.length > 0) {
      return this.props.coreConnected.map((apiItem, index) => {
        return (
          <ListGroupItem key={index}>
            <Row>
              <Col md={2}>
                <i className="fa fa-comments"></i>
              </Col>
              <Col md={10}>
                <span
                  className={
                    apiItem.status === 200 ? "text-success" : "text-danger"
                  }
                >
                  {apiItem.key} API Status
                </span>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <small>{apiItem.message}</small>
              </Col>
            </Row>
          </ListGroupItem>
        );
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col md={12} className="text-center">
            <h6>
              <b>System Settings & Status</b>
            </h6>
          </Col>
        </Row>
        <ListGroup>{this.renderApiInfo()}</ListGroup>
      </React.Fragment>
    );
  }
}
