import {
  faBalanceScale,
  faBalanceScaleLeft,
  faBalanceScaleRight,
  faBriefcase,
  faCalculator,
  faCalendar,
  faChartLine,
  faChartPie,
  faClock,
  faColumns,
  faExchangeAlt,
  faEye,
  faImage,
  faMapMarked,
  faMapMarkerAlt,
  faMapSigns,
  faRedoAlt,
  faRoute,
  faRulerVertical,
  faShip,
  faShippingFast,
  faSquareRootAlt,
  faStopCircle,
  faStopwatch20,
  faSubway,
  faTachometerAlt,
  faTasks,
  faTemperatureHigh,
  faVirusSlash,
} from "@fortawesome/free-solid-svg-icons";
import { getSessionObject } from "./utils/helpers";
export const API_URL = process.env.REACT_APP_API_URL || "";

export const MAP_BOX_TOKEN = process.env.REACT_APP_MAP_BOX_TOKEN || "";

export const PROCESSINGQUERY_REQUEST_TYPES = {
  NLQ: "ProcessNLQ",
  QUERY: "ProcessQuery",
  CMPSMRY: "CompleteSummary",
  LPSNP: "LanePredictionSnap",
};

export function getLogoSource(account) {
  if (account.includes("IFCO")) {
    return "https://www.ifco.com/wp-content/themes/ifco/img/ifco-logo.svg";
  }
  return false;
}

export const menuIcons = [
  {
    label: "Account Summary",
    icon: faBriefcase,
  },
  {
    label: "Account Management",
    icon: faBriefcase,
  },
  {
    label: "Journey Heatmap",
    icon: faMapMarked,
  },
  {
    label: "Calendar",
    icon: faCalendar,
  },
  {
    label: "Covid Sense",
    icon: faVirusSlash,
  },
  {
    label: "Manage Dashboards",
    icon: faColumns,
  },
  {
    label: "Snapshot",
    icon: faImage,
  },
  {
    label: "Distribution",
    icon: faChartPie,
  },
  {
    label: "Location",
    icon: faMapMarkerAlt,
  },
  {
    label: "Punctuality",
    icon: faClock,
  },
  {
    label: "Stoppage",
    icon: faStopCircle,
  },
  {
    label: "Transport Modes",
    icon: faSubway,
  },
  {
    label: "Lane Sight",
    icon: faRoute,
  },
  {
    label: "Lane Comparison",
    icon: faMapSigns,
  },
  {
    label: "Lane Visibility",
    icon: faMapSigns,
  },
  {
    label: "Lane Performance",
    icon: faCalculator,
  },
  {
    label: "Lane Score",
    icon: faCalculator,
  },
  {
    label: "Stoppage Visibility",
    icon: faStopCircle,
  },
  {
    label: "Speed Vs Stoppage",
    icon: faBalanceScaleRight,
  },
  {
    label: "Speed (Land, Air & Ocean)",
    icon: faTachometerAlt,
  },
  {
    label: "Multi-Modal Logistics",
    icon: faTachometerAlt,
  },
  {
    label: "Transit & Dwell Time",
    icon: faStopwatch20,
  },
  {
    label: "Distance & Time",
    icon: faRoute,
  },
  {
    label: "Speed Vs Stoppage",
    icon: faBalanceScaleRight,
  },
  {
    label: "Temperature Tracking",
    icon: faTemperatureHigh,
  },
  {
    label: "Cold Chain",
    icon: faTemperatureHigh,
  },
  {
    label: "MKT Analysis",
    icon: faChartLine,
  },
  {
    label: "Transporter Performance",
    icon: faShippingFast,
  },
  {
    label: "Transporters",
    icon: faShip,
  },
  {
    label: "Drivers",
    icon: faShippingFast,
  },
  {
    label: "Shipments",
    icon: faShip,
  },
  {
    label: "Transitions",
    icon: faExchangeAlt,
  },
  {
    label: "Range",
    icon: faRulerVertical,
  },
  {
    label: "Partners",
    icon: faRulerVertical,
  },
  {
    label: "Transporter Violations",
    icon: faBalanceScaleLeft,
  },
  {
    label: "Summary",
    icon: faBriefcase,
  },
  {
    label: "Analysis",
    icon: faSquareRootAlt,
  },
  {
    label: "Comparision",
    icon: faBalanceScale,
  },
  {
    label: "JourneyMap",
    icon: faMapMarked,
  },
  {
    label: "Asset Summary",
    icon: faBriefcase,
  },
  {
    label: "Dwell",
    icon: faEye,
  },
  {
    label: "Security & Compliance",
    icon: faEye,
  },
  {
    label: "Asset Cycles",
    icon: faRedoAlt,
  },
  {
    label: "Journey Compliance",
    icon: faTasks,
  },
];

export const assetCustomers = ["IFCO"];
let accountName = getSessionObject()
  ? getSessionObject().accountName
  : undefined;
let isAssetCustomer = accountName ? assetCustomers.includes() : false;
export const constantRoutes = [
  {
    title: "Home",
    name: "home",
    link: isAssetCustomer ? "/master/JourneyCountHeatmap" : "/master",
    subMenu: isAssetCustomer
      ? [
          {
            id: 1,
            label: "Journey Heatmap",
            link: "/master/JourneyCountHeatmap",
          },
          { id: 2, label: "Calendar", link: "/master/StatsCalendar" },
          { id: 3, label: "Covid Sense", link: "/master/CovidSense" },
          {
            id: 4,
            label: "Manage Dashboards",
            link: "/dashboards",
          },
        ]
      : [
          { id: 1, label: "Account Summary", link: "/master", icon: "home" },
          {
            id: 2,
            label: "Journey Heatmap",
            link: "/master/JourneyCountHeatmap",
          },
          { id: 3, label: "Calendar", link: "/master/StatsCalendar" },
          { id: 4, label: "Covid Sense", link: "/master/CovidSense" },
          {
            id: 5,
            label: "Manage Dashboards",
            link: "/dashboards",
          },
        ],
  },
  {
    title: "Account",
    name: "account",
    link: "/master/UserProfile/Summary",
    subMenu: [
      {
        id: 1,
        label: "Summary",
        link: "/master/UserProfile/Summary",
        icon: "home",
      },
      {
        id: 2,
        label: "Analysis",
        link: "/master/UserProfile/Analysis",
      },
      { id: 3, label: "Security", link: "/master/UserProfile/Security" },
    ],
  },
];

export const MAPBOX_MAP_STYLES = {
  STREET: "streets-v11",
  DARK: "dark-v10",
  LIGHT: "light-v10",
  SATELLITE: "satellite-v9",
  OUTDOOR: "outdoors-v11",
};

export const MAP_TYPES_OPTIONS = [
  {
    label: "Street",
    value: MAPBOX_MAP_STYLES.STREET,
  },
  {
    label: "Dark",
    value: MAPBOX_MAP_STYLES.DARK,
  },
  {
    label: "Light",
    value: MAPBOX_MAP_STYLES.LIGHT,
  },
  {
    label: "Satellite",
    value: MAPBOX_MAP_STYLES.SATELLITE,
  },
  {
    label: "Outdoor",
    value: MAPBOX_MAP_STYLES.OUTDOOR,
  },
];

export const filterOption = {
  Transport_Modes: {
    values: ["Ocean Mode", "Flight Mode", "Land Mode"],
  },
  Lane_Filters: {
    values: ["Transporters", "Stopages", "ETA", "Origins", "Destinations"],
  },
};

export const countryList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua &amp; Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia &amp; Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote D Ivoire",
  "Croatia",
  "Cruise Ship",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Polynesia",
  "French West Indies",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kuwait",
  "Kyrgyz Republic",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Pierre &amp; Miquelon",
  "Samoa",
  "San Marino",
  "Satellite",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "St Kitts &amp; Nevis",
  "St Lucia",
  "St Vincent",
  "St. Lucia",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor L'Este",
  "Togo",
  "Tonga",
  "Trinidad &amp; Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks &amp; Caicos",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "Uruguay",
  "Uzbekistan",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (US)",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const menus = [
  {
    title: "Home",
    name: "Home",
    link: "/master",
    subMenu: [
      { id: 1, label: "Account Summary", link: "/master", icon: "home" },
      {
        id: 2,
        label: "Journey Heatmap",
        link: "/master/JourneyCountHeatmap",
      },
      { id: 3, label: "Calendar", link: "/master/StatsCalendar" },
      { id: 4, label: "Covid Sense", link: "/master/CovidSense" },
      {
        id: 5,
        label: "Manage Dashboards",
        link: "/dashboards",
      },
    ],
  },
  {
    title: "Lane Performance",
    name: "Lane Performance",
    link: "/master/Performance/Snapshot",
    subMenu: [
      {
        id: 1,
        label: "Snapshot",
        link: "/master/Performance/Snapshot",
        icon: "home",
      },
      {
        id: 2,
        label: "Distribution",
        link: "/master/Performance/Distribution",
      },
      {
        id: 3,
        label: "Location",
        link: "/master/Performance/Locations",
      },
      {
        id: 4,
        label: "Punctuality",
        link: "/master/Performance/Punctuality",
      },
      { id: 5, label: "Stoppage", link: "/master/Performance/Stoppage" },
      {
        id: 6,
        label: "Transport Modes",
        link: "/master/Performance/TransportModes",
      },
    ],
  },
  {
    title: "Lane Visibility",
    name: "Lane Visibility",
    link: "/master/Visibility/LaneSight",
    subMenu: [
      {
        id: 1,
        label: "Lane Sight",
        link: "/master/Visibility/LaneSight",
        icon: "home",
      },
      {
        id: 2,
        label: "Lane Comparison",
        link: "/master/visibility/LaneComparison",
      },
      { id: 3, label: "Lane Score", link: "/master/visibility/LaneScore" },
      {
        id: 4,
        label: "Stoppage Visibility",
        link: "/master/visibility/StoppageVisibility",
      },
      {
        id: 5,
        label: "Speed Vs Stoppage",
        link: "/master/visibility/SpeedStoppage",
      },
    ],
  },
  {
    title: "Multi-Modal Logistics",
    name: "Multi-Modal Logistics",
    link: "/master/MultiModalAnalysis/SpeedLandAirOcean",
    subMenu: [
      {
        id: 1,
        label: "Speed (Land, Air & Ocean)",
        link: "/master/MultiModalAnalysis/SpeedLandAirOcean",
        icon: "home",
      },
      {
        id: 2,
        label: "Transit & Dwell Time",
        link: "/master/MultiModalAnalysis/TransitDwellTime",
      },
      {
        id: 3,
        label: "Distance & Time",
        link: "/master/MultiModalAnalysis/DistanceTime",
      },
      {
        id: 4,
        label: "Distribution",
        link: "/master/MultiModalAnalysis/Distribution",
      },
    ],
  },
  {
    title: "Cold Chain",
    name: "Cold Chain",
    link: "/master/ColdChain/Tracking",
    subMenu: [
      {
        isGroup: true,
        label: "Visibility",
        groupItems: [
          {
            id: 1,
            label: "Temperature Tracking",
            link: "/master/ColdChain/Tracking",
            icon: "home",
          },
          {
            id: 2,
            label: "MKT Analysis",
            link: "/master/ColdChain/MKTAnalysis",
          },
          {
            id: 3,
            label: "Lane Comparison",
            link: "/master/ColdChain/Comparison",
          },
          {
            id: 4,
            label: "Lane Score",
            link: "/master/ColdChain/Score",
          },
          {
            id: 5,
            label: "Transporter Performance",
            link: "/master/ColdChain/Performance",
          },
        ],
      },
      {
        isGroup: true,
        label: "Analysis",
        groupItems: [
          {
            id: 6,
            label: "Shipments",
            link: "/master/ColdChain/Analytics/Shipments",
            icon: "home",
          },
          {
            id: 7,
            label: "Transitions",
            link: "/master/ColdChain/Analytics/Transitions",
          },
          {
            id: 8,
            label: "Range",
            link: "/master/ColdChain/Analytics/Range",
          },
          {
            id: 9,
            label: "Transporter Violations",
            link: "/master/ColdChain/Analytics/Violations",
          },
        ],
      },
    ],
  },
  {
    title: "Transporters",
    name: "Transporters",
    link: "/master/Transporters/Summary",
    subMenu: [
      {
        id: 1,
        label: "Summary",
        link: "/master/Transporters/Summary",
        icon: "home",
      },
      {
        id: 2,
        label: "Analysis",
        link: "/master/Transporters/Analysis",
      },
      {
        id: 3,
        label: "Comparision",
        link: "/master/Transporters/TransportersComparison",
      },
      {
        id: 4,
        label: "JourneyMap",
        link: "/master/Transporters/TransportersJourneyMap",
      },
    ],
  },
  {
    title: "Drivers",
    name: "Drivers",
    link: "/master/Drivers/Summary",
    subMenu: [
      {
        id: 1,
        label: "Summary",
        link: "/master/Drivers/Summary",
        icon: "home",
      },
      {
        id: 2,
        label: "Analysis",
        link: "/master/Drivers/Analysis",
      },
      {
        id: 3,
        label: "Comparision",
        link: "/master/Drivers/DriversComparison",
      },
      {
        id: 4,
        label: "JourneyMap",
        link: "/master/Drivers/DriversJourneyMap",
      },
    ],
  },
  {
    title: "Partners",
    name: "Partners",
    link: "/master/Partners/Summary",
    subMenu: [
      {
        id: 1,
        label: "Summary",
        link: "/master/Partners/Summary",
        icon: "home",
      },
      {
        id: 2,
        label: "Analysis",
        link: "/master/Partners/Analysis",
      },
      {
        id: 3,
        label: "Comparision",
        link: "/master/Partners/PartnersComparison",
      },
      {
        id: 4,
        label: "JourneyMap",
        link: "/master/Partners/PartnersJourneyMap",
      },
    ],
  },
  {
    title: "Asset Management",
    name: "Asset Management",
    link: "/master/asset/visibility/AssetSummary",
    subMenu: [
      {
        id: 1,
        label: "Asset Summary",
        link: "/master/asset/visibility/AssetSummary",
        icon: "home",
      },
      {
        id: 2,
        label: "Dwell Time Visibility",
        link: "/master/asset/visibility/DwellTimeVisibility",
      },
      {
        id: 3,
        label: "Asset Cycles",
        link: "/master/asset/visibility/AssetCycles",
      },
    ],
  },
  {
    title: "Security & Compiance",
    name: "Security & Compiance",
    link: "/master/Compliance/Journey",
    subMenu: [
      {
        id: 1,
        label: "Journey Compliance",
        link: "/master/Compliance/Journey",
        icon: "home",
      },
    ],
  },
];

export const dashboards = [
  {
    name: "Lane Performance Analysis",
    picture: './src/addDashboard/LanePerformanceAnalysis.png";',
    description:
      "View historic trends of Shipment performance on all your lanes",
  },
  {
    name: "Lane Visibility Analysis",
    picture: './src/addDashboard/LaneVisibilityAnalysis.png";',
    description:
      "Verify the shipment routes and stoppage trends as well view the lane performance score",
  },
  {
    name: "Multi-modal Analysis",
    picture: './src/addDashboard/Multi-modalAnalysis.png";',
    description:
      "Compare shipment performance across different modes of transport across all your lanes",
  },
  {
    name: "Cold Chain Analysis",
    picture: './src/addDashboard/ColdChainAnalysis.png";',
    description:
      "Explore in depth analysis of Temperature trends and possible violations of Shipment Temperature",
  },
  {
    name: "Transporter Analysis",
    picture: './src/addDashboard/TransporterAnalysis.png";',
    description:
      "Identify best Trannsporters for transporting your shipments safely and on time",
  },
  {
    name: "Driver Analysis",
    picture: './src/addDashboard/DriverAnalysis.png";',
    description:
      "Identify best Drivers for transporting your shipments safely and on time",
  },
  {
    name: "Compliance Analysis",
    picture: './src/addDashboard/ComplianceAnalysis.png";',
    description:
      "Verify if on ground parameters match the SLAs agreed with the business",
  },
  {
    name: "Asset Analysis",
    picture: './src/addDashboard/AssetAnalysis.png";',
    description: "Monitor current and historic performance of your assets",
  },
];
