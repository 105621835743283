import get from "lodash/get";
import { connect } from "react-redux";
import { logMeIn as logMeInAction } from "../../../state/actions/coreAPI";
import { generateQueryProsKey } from "../../../utils/helpers";
import ContactUsForm from "../../partials/contactUs/ContactUsForm";

export function mapStateToProps(state, ownProps) {
  const queryKey = `login.${generateQueryProsKey(
    "",
    JSON.stringify(ownProps.optionKey),
    "LoginUser",
    "",
    undefined,
    ""
  )}`;
  return {
    data: get(state.appData, queryKey) || [],
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    submitContactUsQuery: (optionKey) => {
      return dispatch(logMeInAction("", optionKey, "LoginUser", "", undefined));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ContactUsForm);
