import get from "lodash/get";
import { connect } from "react-redux";
import { queryProcessingIn as queryProcessingInAction } from "../../../state/actions/coreAPI";
import { generateQueryProsKey } from "../../../utils/helpers";
import AsideContent from "../../partials/appAside/AsideContent";

function mapStateToProps(state, ownProps) {
  const queryKey = `processingQueryIn.${generateQueryProsKey(
    ownProps.queryText,
    JSON.stringify({
      db_specification: "AC",
      fields: {
        field_clause: "NONE",
        all_fields_list: ["ACCOUNT_UUID", "ACCOUNT_NAME"],
      },
    }),
    "ProcessQuery",
    "sql",
    "",
    "GUID"
  )}`;

  return {
    data: get(state, `appData.${queryKey}`) || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getQueryProcessingIn: (queryText, optionKey) => {
      return dispatch(
        queryProcessingInAction(
          queryText,
          optionKey,
          "ProcessQuery",
          "sql",
          "",
          "GUID"
        )
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AsideContent);
