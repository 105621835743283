// import { Notification } from "element-react";
import {
  checkCoreConnection as checkCoreConnectionAPI,
  logMeIn as logMeInAPI,
  queryProcessingIn as queryProcessingInAPI,
} from "../../services/core";
import { createActions } from "../../utils/helpers";

export const CORECONNECTION_API_FETCH = "CORECONNECTION_API_FETCH",
  CORECONNECTION_API_SUCCESS = "CORECONNECTION_API_SUCCESS",
  CORECONNECTION_API_FAILED = "CORECONNECTION_API_FAILED";

export const PROSSQUERIN_API_FETCH = "PROSSQUERIN_API_FETCH",
  PROSSQUERIN_API_SUCCESS = "PROSSQUERIN_API_SUCCESS",
  PROSSQUERIN_API_FAILED = "PROSSQUERIN_API_FAILED";
export const LOGIN_API_FETCH = "LOGIN_API_FETCH",
  LOGIN_API_SUCCESS = "LOGIN_API_SUCCESS",
  LOGIN_API_FAILED = "LOGIN_API_FAILED";

export const coreConnectionCheck = (dto) => async (dispatch) => {
  try {
    dispatch(createActions(CORECONNECTION_API_FETCH));
    const apiData = await checkCoreConnectionAPI(dto);

    dispatch(createActions(CORECONNECTION_API_SUCCESS, apiData));
    return apiData;
  } catch (e) {
    dispatch(createActions(CORECONNECTION_API_FAILED, e.message));
  }
};

export const queryProcessingIn = (
  queryString,
  optionKey,
  requestType,
  subQueryText,
  requestSpec,
  sessionGuid
) => async (dispatch) => {
  try {
    dispatch(
      createActions(PROSSQUERIN_API_FETCH, {
        queryString,
        optionKey,
        requestType,
        subQueryText,
        requestSpec,
        sessionGuid,
      })
    );
    const apiData = await queryProcessingInAPI(
      queryString,
      optionKey,
      requestType,
      subQueryText,
      requestSpec,
      sessionGuid
    );

    dispatch(
      createActions(PROSSQUERIN_API_SUCCESS, {
        queryString,
        optionKey,
        requestType,
        subQueryText,
        requestSpec,
        sessionGuid,
        data: apiData,
      })
    );

    return apiData;
  } catch (e) {
    // Notification.error({
    //   title: "Oops",
    //   message: e.message,
    // });
    dispatch(
      createActions(PROSSQUERIN_API_FAILED, {
        queryString,
        optionKey,
        requestType,
        subQueryText,
        requestSpec,
        sessionGuid,
        error: e.message,
      })
    );
  }
};

export const logMeIn = (
  queryString,
  optionKey,
  requestType,
  subQueryText,
  requestSpec
) => async (dispatch) => {
  try {
    dispatch(
      createActions(PROSSQUERIN_API_FETCH, {
        queryString,
        optionKey,
        requestType,
        subQueryText,
        requestSpec,
      })
    );
    const apiData = await logMeInAPI(
      queryString,
      optionKey,
      requestType,
      subQueryText,
      requestSpec
    );

    dispatch(
      createActions(PROSSQUERIN_API_SUCCESS, {
        queryString,
        optionKey,
        requestType,
        subQueryText,
        requestSpec,
        data: apiData,
      })
    );

    return apiData;
  } catch (e) {
    dispatch(
      createActions(PROSSQUERIN_API_FAILED, {
        queryString,
        optionKey,
        requestType,
        subQueryText,
        requestSpec,
        error: e.message,
      })
    );
  }
};
