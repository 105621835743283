/* eslint-disable */
import { compact, map, uniq } from "lodash";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { client } from "./api-client";

export const getAssetLabel = () => {
  const label = [
    {
      account: "IFCO",
      replacerText: "RPC",
    },
  ];
  let text = "Asset";
  label.map((l) => {
    if (getSessionObject().accountName.includes(l.account)) {
      text = l.replacerText;
    }
  });
  return text;
};

export const styles = {
  card: {
    backgroundColor: "white",
    height: "95%",
    flex: 1,
    padding: "2em",
    borderRadius: "1em",
    margin: "10px 10px",
  },
};

export function connectWithStore(store, ...args) {
  return (WrappedComponent) => {
    var ConnectedWrappedComponent = connect(...args)(WrappedComponent);
    return (props) => {
      return <ConnectedWrappedComponent {...props} store={store} />;
    };
  };
}

export const createActions = (type, payload) => {
  const dto = {
    type,
  };

  if (payload) {
    dto.payload = payload;
  }

  return dto;
};

export const generateKeyFromArgs = (...args) => compact(args).join("_");

export const generateQueryProsKey = (
  queryString,
  optionKey,
  requestType,
  subQueryText,
  requestSpec,
  sessionGuid
) =>
  compact([
    queryString,
    optionKey ? optionKey.replace(/\{|\}|,|"|\/|\[|\]|_|:/gi, "") : "",
    requestType,
    subQueryText,
    requestSpec,
    sessionGuid,
  ]).join("_");

export const UILables = {};

export function ValidateSession() {
  var sessionKey = sessionStorage.getItem("CoreSessionKey");
  if (sessionKey === null) {
    //||  !isGuid(sessionKey)
    return false;
  } else {
    return true;
  }
}

export function CreateUUIDv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function setSessionObject(customer, accountId, emailId) {
  if (customer) {
    localStorage.setItem("accountName", customer);
  }
  if (accountId) {
    localStorage.setItem("sessionGuid", accountId);
  }
  if (emailId) {
    localStorage.setItem("emailId", emailId);
  }
}

export const getSessionObject = () => {
  const accountName = localStorage.getItem("accountName");
  const sessionGuid = localStorage.getItem("sessionGuid");
  const email = localStorage.getItem("emailId");

  if (accountName && sessionGuid) {
    return {
      accountName,
      sessionGuid,
      email,
    };
  }
};

export const clearSessionObject = () => {
  localStorage.removeItem("accountName");
  localStorage.removeItem("sessionGuid");
  localStorage.removeItem("emailId");
};

export function GetTodaysDate() {
  //MM/DD/YY
  // debugger;
  var date = new Date();
  var month = date.getMonth() + 1;
  if (String(month).length === 1) {
    month = String(month).padStart(2, "0");
  }
  var day = date.getDate();
  if (String(day).length === 1) {
    day = String(day).padStart(2, "0");
  }
  var todaysDate = month + "/" + day + "/" + date.getFullYear();
  return todaysDate;
}

const changeDayStatus = (date, data, type) => {
  if (data && data[type]) {
    data = Object.assign(data, {
      [type]: data[type] + 1,
    });
  } else {
    if (data === undefined) data = {};
    data = Object.assign(data, {
      [type]: 1,
    });
  }
  return data;
};

export function toBarSeriesData(barChartData) {
  let dataMap = new Map();
  let updatedDetails;
  let dayDetails;
  let dateArr = [];
  let formattedDays = [];

  barChartData.map((journeyDeatils) => {
    let day = moment(journeyDeatils.date).format("DD/MM/YYYY");
    if (journeyDeatils.journeyStatus === "EARLY") {
      dateArr.push(journeyDeatils.date);
      dayDetails = dataMap.get(day);
      updatedDetails = changeDayStatus(day, dayDetails, "EARLY");
      dataMap.set(day, updatedDetails);
    } else if (journeyDeatils.journeyStatus === "LATE") {
      dateArr.push(journeyDeatils.date);
      dayDetails = dataMap.get(day);
      updatedDetails = changeDayStatus(day, dayDetails, "LATE");
      dataMap.set(day, updatedDetails);
    } else if (journeyDeatils.journeyStatus === "TERMINATED") {
      dateArr.push(journeyDeatils.date);
      dayDetails = dataMap.get(day);
      updatedDetails = changeDayStatus(day, dayDetails, "TERMINATED");
      dataMap.set(day, updatedDetails);
    } else if (journeyDeatils.journeyStatus === "ONTIME") {
      dateArr.push(journeyDeatils.date);
      dayDetails = dataMap.get(day);
      updatedDetails = changeDayStatus(day, dayDetails, "ONTIME");
      dataMap.set(day, updatedDetails);
    }
  });

  dateArr.sort();

  dateArr.map((date, index) => {
    let day = moment(date).format("DD/MM/YYYY");
    formattedDays.push(day);
  });

  let sortedByDate = uniq(formattedDays);
  //Do not change the sequence in below
  let status = ["EARLY", "LATE", "TERMINATED", "ONTIME"];
  let result = [
    {
      label: "EARLY",
      data: [],
    },
    {
      label: "LATE",
      data: [],
    },
    {
      label: "TERMINATED",
      data: [],
    },
    {
      label: "ONTIME",
      data: [],
    },
  ];

  sortedByDate.map((day) => {
    let dateStatus = dataMap.get(day);
    if (dateStatus) {
      for (let i = 0; i < status.length; i++) {
        if (dateStatus[status[i]]) {
          result[i].data.push({
            x: day,
            y: dateStatus[status[i]],
          });
        } else {
          result[i].data.push({
            x: day,
            y: 0,
          });
        }
      }
    }
  });

  return result;
}

export const toLineSeriseData = (values) => {
  const seriseData = toBarSeriesData(values);

  const lineSeriseData = [];
  map(seriseData, (lineData) => {
    let data = [];
    for (let i = 0; i < lineData.data.length; i++) {
      const strDate = lineData.data[i].x.split("/");
      const someDate = `${strDate[1]}/${strDate[0]}/${strDate[2]}`;

      const date = moment(someDate, "MM/DD/YYYY");
      data.push({
        x: +date.valueOf(),
        y: lineData.data[i].y,
      });
    }
    lineSeriseData.push({
      data: data,
      disabled: false,
      title: lineData.label,
    });
  });

  return lineSeriseData;
};

const updateStats = (type, value, data) => {
  if (
    data &&
    data["actual_transit_time_hour"] &&
    data["total_stopped_time_hour"]
  )
    debugger;
  let updatedDetails;
  if (!data) {
    updatedDetails = Object.assign(
      {},
      {
        [type]: value,
      }
    );
  } else if (data[type]) {
    updatedDetails = Object.assign(data, {
      [type]: data[type] + value,
    });
  } else if (!data[type]) {
    updatedDetails = Object.assign(data, {
      [type]: value,
    });
  }
  if (!updatedDetails);
  return updatedDetails;
};

export function toPunctualityBarGraph(data) {
  let dataMap = new Map();
  let laneCollection = [];
  let result;

  data.map(
    (
      { actual_transit_time_hour, total_stopped_time_hour, lane_name },
      index
    ) => {
      let previousData = dataMap.get(lane_name);
      laneCollection.push(lane_name);
      let updateStatus;
      if (actual_transit_time_hour) {
        updateStatus = updateStats(
          "actual_transit_time_hour",
          actual_transit_time_hour,
          previousData
        );
        dataMap.set(lane_name, updateStatus);
      }
      if (total_stopped_time_hour) {
        previousData = dataMap.get(lane_name);
        updateStatus = updateStats(
          "total_stopped_time_hour",
          total_stopped_time_hour,
          previousData
        );
        dataMap.set(lane_name, updateStatus);
      }
    }
  );

  laneCollection = uniq(laneCollection);
  result = [
    {
      label: "actual_transit_time_hour",
      data: [],
    },
    {
      label: "total_stopped_time_hour",
      data: [],
    },
  ];

  laneCollection.map((lane, i) => {
    let laneData = dataMap.get(lane);
    result.map((laneObj, index) => {
      result[index].data.push({
        x: `${lane}`,
        y: laneData[laneObj.label],
      });
    });
  });
  return result;
}

export function distanceTimeBarGraph(data) {
  let dataMap = new Map();
  let laneCollection = [];
  let result;
  data.map(
    (
      { journey_distance_km, journey_time_hour, lane_name },
      index
      ) => {
        let previousData = dataMap.get(lane_name);
        laneCollection.push(lane_name);
        let updateStatus;
        if (journey_distance_km) {
          updateStatus = updateStats(
            "journey_distance_km",
            journey_distance_km,
            previousData
            );
            dataMap.set(lane_name, updateStatus);
          }
          if (journey_time_hour) {
            previousData = dataMap.get(lane_name);
            updateStatus = updateStats(
              "journey_time_hour",
              journey_time_hour,
              previousData
              );
              dataMap.set(lane_name, updateStatus);
            }
          }
        );
          
          laneCollection = uniq(laneCollection);
          result = [
            {
              label: "journey_distance_km",
              data: [],
            },
            {
              label: "journey_time_hour",
              data: [],
            },
          ];
          
          laneCollection.map((lane, i) => {
            let laneData = dataMap.get(lane);
            result.map((laneObj, index) => {
      result[index].data.push({
        x: `${lane}`,
        y: laneData[laneObj.label],
      });
    });
  });
  return result;
}

export const getMilageToDealyData = (data) => {
  let result = [
    {
      label: "distance_travelled_km",
      data: [],
    },
    {
      label: "delay_delta_hr",
      data: [],
    },
  ];
  data.map(({ delay_delta_hr, distance_travelled_km, lane_name }, index) => {
    result[0].data.push({
      x: `${lane_name}`,
      y: distance_travelled_km,
    });
    result[1].data.push({
      x: `${lane_name}`,
      y: delay_delta_hr,
    });
  });
  return result;
};

export const getMovementVsStoppageData = (data) => {
  let resultTransit = [];
  let resultStoppage = [];
  data.map(
    ({ actual_transit_percent, actual_stopped_percent, lane_name }, index) => {
      resultStoppage.push({
        lane: lane_name,
        data: [
          {
            x: index,
            y: actual_stopped_percent,
          },
        ],
      });
      resultTransit.push({
        lane: lane_name,
        data: [
          {
            x: index,
            y: actual_transit_percent,
          },
        ],
      });
    }
  );
  let result = [resultTransit, resultStoppage];
  return result;
};

export const getLocationTabDelayGraph = (data) => {
  let travelTime = [];
  let stoppageTime = [];
  data.map(({ total_moving_time, total_stopped_time, lane_name }) => {
    travelTime.push({
      x: lane_name,
      y: total_moving_time,
    });
    stoppageTime.push({
      x: lane_name,
      y: total_stopped_time,
    });
  });
  let result = [travelTime, stoppageTime];
  return result;
};

export const convertXY1Y2toXY1andXY2 = (data, header, yKeyCount) => {
  let seriesOne = [];
  let seriesTwo = [];
  let seriesThree = [];
  let x_key = header.x;
  let y1_key = header.y1;
  let y2_key = header.y2;
  let y3_key;
  if (yKeyCount === 3) {
    y3_key = header.y3;
  }
  data.map((gItem, i) => {
    seriesOne.push({
      x: gItem[x_key],
      y: gItem[y1_key],
    });
    seriesTwo.push({
      x: gItem[x_key],
      y: gItem[y2_key],
    });
    if (yKeyCount === 3) {
      seriesThree.push({
        x: gItem[x_key],
        y: gItem[y3_key],
      });
    }
  });
  let result = [seriesOne, seriesTwo];
  if (yKeyCount === 3) {
    result.push(seriesThree);
  }
  return result;
};

export const distanceBetweenLocations = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // km (change this constant to get miles)
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;

  return d * 1000;
};

export const barGraphWidth = (data) => {
  //set width for graph based on length
  //to be used for scrollable graph
  if (data && data.length > 0) {
    return 1500;
  }
};

export const humanize = (str) => {
  let i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
};

export const getBounds = (graphData) => {
  let maxLatitude = maxBy(graphData, "latitude");
  let minLatitude = minBy(graphData, "latitude");
  let maxLongitude = maxBy(graphData, "longitude");
  let minLongitude = minBy(graphData, "longitude");
  return [
    [minLongitude.longitude, minLatitude.latitude],
    [maxLongitude.longitude, maxLatitude.latitude],
  ];
};

export const firstDayOfWeek = (year, week) => {
  // Jan 1 of 'year'
  var d = new Date(parseInt(year), 0, 1),
    offset = d.getTimezoneOffset();

  // ISO: week 1 is the one with the year's first Thursday
  // so nearest Thursday: current date + 4 - current day number
  // Sunday is converted from 0 to 7
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));

  // 7 days * (week - overlapping first week)
  d.setTime(
    d.getTime() +
      7 * 24 * 60 * 60 * 1000 * (week + (year == d.getFullYear() ? -1 : 0))
  );

  // daylight savings fix
  d.setTime(d.getTime() + (d.getTimezoneOffset() - offset) * 60 * 1000);

  // back to Monday (from Thursday)
  d.setDate(d.getDate() - 3);

  return d;
};

export function fetchItems({
  pageFilterType,
  type,
  timelineInfo,
  filterItemInfo,
  dataDuration,
  pageFilters,
  dataType,
  pageId = "Compliance",
  pageSubId = "Journey",
  viewData = [
    {
      viewType: "item_list_value",
      dataTypes: [
        { header: "AllLanes", fields: ["lane_name", "journey_count"] },
      ],
    },
  ],
  requestType = "FilteredPeriodData",
}) {
  if (type === "stacked_bar_chart") {
    viewData = [
      {
        viewType: "stacked_bar_chart",
        dataTypes: [{ header: dataType, fields: ["DriverName"] }],
      },
    ];
  }
  let body = {
    RequestType: "ComponentRequest",
    OptionKey: {
      pageId,
      pageSubId,
      requestType: requestType,
      viewData,
      pageFilters: {
        typeInfo: {
          values: [pageFilterType],
        },
        timelineInfo: {
          values: [timelineInfo],
        },
        filterItemInfo: {
          values: [filterItemInfo],
        },
      },
    },
    SessionObj: getSessionObject(),
  };
  if (pageFilters) {
    body.OptionKey.pageFilters = pageFilters;
  }
  if (dataDuration) {
    body.OptionKey.dataDuration = {
      start_date: dataDuration.start,
      end_date: dataDuration.end,
    };
  }
  return client(body);
}

export const serializeData = (rawData) => {
  Array.prototype.move = function (from, to) {
    this.splice(to, 0, this.splice(from, 1)[0]);
  };
  let keys = Object.keys(rawData);
  let datasets = [];
  let labels = [];
  keys.map((key) => {
    let data = [];
    rawData[key].map((node) => {
      data.push(node.y);
      if (!labels.includes(node.x)) {
        labels.push(node.x);
      }
    });
    let yAxisID = key === "shipments" ? "y-axis-2" : "y-axis-1";
    let type = key === "shipments" ? "line" : "bar";
    let label =
      key === "non_violations"
        ? "Non-Compliance"
        : key === "violations"
        ? "Compliance"
        : key;
    datasets.push({
      label,
      data,
      yAxisID,
      type,
    });
  });
  datasets.map((d) => {
    if (d.label === "Compliance") {
      d.backgroundColor = "rgba(0, 122, 255, 1)";
    } else if (d.label === "Non-Compliance") {
      d.backgroundColor = "rgba(52, 199, 199, 1)";
    } else if (d.label === "shipments") {
      d.backgroundColor = "rgba(255, 149, 0, 1)";
      d.fill = false;
      d.borderColor = "rgba(255, 149, 0, 1)";
      d.steppedLine = true;
    }
  });
  datasets.move(2, 0);
  return { datasets, labels };
};

export const restructureTreeData = (tripsCenterFilters) => {
  let centers = [];
  tripsCenterFilters.map((center, i) => {
    let countries = center.country;
    let centerChildren = [];
    countries.map((country, k) => {
      let states = country.states;
      let countryChildren = [];
      states.map((s) => {
        let centers = s.centers;
        let stateChildren = [];
        centers.map((c) => {
          stateChildren.push({
            label: c.store_name,
          });
        });
        countryChildren.push({
          label: s.state_name,
          children: stateChildren,
        });
      });
      centerChildren.push({
        id: i,
        label: country.country_name,
        children: countryChildren,
      });
    });
    centers.push({
      id: i,
      label: center.center_type,
      children: centerChildren,
    });
  });
  let data = [
    {
      id: 1,
      label: "level one 1",
      children: [
        {
          id: 4,
          label: "level two 1-1",
          children: [
            {
              id: 9,
              label: "level three 1-1-1",
            },
            {
              id: 10,
              label: "level three 1-1-2",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      label: "level one 2",
      children: [
        {
          id: 5,
          label: "level two 2-1",
        },
        {
          id: 6,
          label: "level two 2-2",
        },
      ],
    },
    {
      id: 3,
      label: "level one 3",
      children: [
        {
          id: 7,
          label: "level two 3-1",
        },
        {
          id: 8,
          label: "level two 3-2",
        },
      ],
    },
  ];
  return centers;
};
