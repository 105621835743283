import get from "lodash/get";
import React from "react";
import {
  Alert,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
} from "reactstrap";
import "./LoginForm.scss";

export default class LoginForm extends React.Component {
  state = {
    loading: false,
    formValues: {
      username: "",
      password: "",
    },
    errorStates: {},
  };

  render() {
    let e = this.props.apiErrors.error;
    return (
      <Form onSubmit={this.onSubmit}>
        {e ? (
          e === "Error occured while queryProcessingIn" ? null : (
            <Alert color="warning">{this.props.apiErrors.error} </Alert>
          )
        ) : null}
        <h1>Sign in to Reveal</h1>
        <h3>Enter your details below</h3>
        <FormGroup>
          <label>Email address</label>
          <Input
            name="username"
            id="username"
            placeholder="Username"
            onChange={this.handleChange}
            value={this.state.formValues.username}
            invalid={!!this.state.errorStates["username"]}
            disabled={this.state.loading}
          />
          <FormFeedback className="d-block">
            {this.state.errorStates["username"]}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <div
            className="password-label"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <label>Password</label>
            <a
              style={{ cursor: "pointer" }}
              href="https://portal.roambee.com/#login"
            >
              Forgot your password?
            </a>
          </div>
          <Input
            name="password"
            id="password"
            placeholder="Password"
            type="password"
            onChange={this.handleChange}
            value={this.state.formValues.password}
            invalid={!!this.state.errorStates["password"]}
            disabled={this.state.loading}
          />
          <FormFeedback className="d-block">
            {this.state.errorStates["password"]}
          </FormFeedback>
        </FormGroup>
        <div
          className="button-container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            className="ui-button-rounded ui-button-warning"
            id="sign-in-button"
            color="primary"
            block
            disabled={this.state.loading}
            type="submit"
          >
            Login
            {this.state.loading ? (
              <div className="spinner-border-sm spinner-border ml-2" />
            ) : (
              <React.Fragment />
            )}
          </Button>
        </div>
      </Form>
    );
  }

  onSubmit = async (e) => {
    e.preventDefault();
    try {
      this.setState({
        loading: true,
      });
      if (this.formValidations()) {
        const optionKey = this.props.optionKey;

        optionKey.pageFilters.loginInfo.values = [
          {
            userName: this.state.formValues.username,
            userPassword: this.state.formValues.password,
          },
        ];

        const loginResp = get(
          await this.props.doLogin(JSON.stringify(optionKey)),
          "0"
        );

        if (loginResp) {
          this.props.setCurrentUser(
            loginResp["account"],
            loginResp["session_id"],
            this.state.formValues.username
          );
        }

        return loginResp;
      }
    } catch (e) {
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  formValidations = () => {
    const errorStates = this.state.errorStates;
    var formValues = this.state.formValues;

    let validation = true;

    if (!formValues["password"] || !formValues["password"].trim().length) {
      errorStates["password"] = "Required";
      validation = false;
    }

    if (!formValues["username"] || !formValues["username"].trim().length) {
      errorStates["username"] = "Required";
      validation = false;
    }

    this.setState({
      errorStates,
    });

    return validation;
  };

  handleChange = (event) => {
    const formValues = this.state.formValues;
    formValues[event.target.id] = event.target.value;

    const errorStates = this.state.errorStates;
    errorStates[event.target.id] = "";

    this.setState({ formValues });
  };
}
