import { connect } from "react-redux";
import { logMeIn as logMeInAction } from "../../../state/actions/coreAPI";
import { setComponentContext as setComponentContextAction } from "../../../state/actions/setAppContext";
import LoginForm from "../../partials/login/LoginForm";
import { currentUser } from "../../partials/SessionContext";

export function mapStateToProps(state, ownProps) {
  return { apiErrors: state.apiErrors };
}

export function mapDispatchToProps(dispatch) {
  return {
    doLogin: async (optionKey) => {
      const value = await dispatch(
        logMeInAction("", optionKey, "ComponentRequest")
      );
      return value;
    },
    setAppContext: (key, value) => {
      return dispatch(setComponentContextAction(key, value));
    },
  };
}

export default currentUser(
  connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
  })(LoginForm)
);
