import compact from "lodash/compact";
import isUndefined from "lodash/isUndefined";
import map from "lodash/map";
import React from "react";
import { Route, Switch } from "react-router-dom";
import SuspenseLoader from "../partials/SuspenseLoader";

// const HelpAndDoc = React.lazy(() => import("../pages/HelpAndDoc"));
// const MasterDashBoard = React.lazy(() => import('../pages/MasterDashBoard'));
const UserProfileHome = React.lazy(() => import("../pages/UserProfileHome"));
const AdminHome = React.lazy(() => import("../pages/AdminHome"));
const ComplianceHome = React.lazy(() => import("../pages/ComplianceHome"));
const DiscoveryHome = React.lazy(() => import("../pages/DiscoveryHome"));
// const MasterHome = React.lazy(() => import("../pages/MasterHome"));
const Home = React.lazy(() => import("../pages/Home/Home"));
// const RbStudio = React.lazy(() => import("../pages/RbStudio"));
const MasterHomeCovid = React.lazy(() => import("../pages/MasterHomeCovid"));
const AccountAbstract = React.lazy(() => import("../pages/AccountAbstract"));
const JourneyCountHeatmap = React.lazy(() =>
  import("../pages/JourneyCountHeatmap")
);
const StatsCalendar = React.lazy(() => import("../pages/StatsCalenderPage"));
const GlobalSummary = React.lazy(() => import("../pages/GlobalSummary"));
const AllReports = React.lazy(() => import("../pages/AllReports"));
const SecurityMain = React.lazy(() => import("../pages/SecurityMain"));
// const PerformanceDetails = React.lazy(() =>
//   import("../pages/PerformanceDetails")
// );
// const ComplianceDetails = React.lazy(() =>
//   import('../pages/ComplianceDetails')
// );
const LanePredictionMain = React.lazy(() => import("../pages/LanePrediction"));
const PerformanceHome = React.lazy(() => import("../pages/PerformanceHome"));
const TransportersHome = React.lazy(() => import("../pages/TransportersHome"));
const DriversHome = React.lazy(() => import("../pages/DriversHome"));
const PartnersHome = React.lazy(() => import("../pages/PartnersHome"));
const FreightageHome = React.lazy(() => import("../pages/FreightageHome"));
const VisibilityHome = React.lazy(() => import("../pages/VisibilityHome"));
const MultiModalVisibility = React.lazy(() =>
  import("../pages/MultiModalVisibility")
);
const MultiModalAnalysis = React.lazy(() =>
  import("../pages/MultiModalAnalysis")
);
const ColdChainHome = React.lazy(() => import("../pages/ColdChainHome"));
const ColdChainAnalyticsHome = React.lazy(() =>
  import("../pages/ColdChainAnalytics")
);
const AssetVisibilityHome = React.lazy(() =>
  import("../containers/asset/visibility/AssetVisibilityHome")
);
const AssetAnalysisHome = React.lazy(() =>
  import("../pages/AssetAnalysisHome")
);
const ManageDashboard = React.lazy(() => import("../pages/ManageDashboard"));
const AreaGraph = React.lazy(() => import("../partials/area"));
const RpcAnalyzer = React.lazy(() =>
  import("../pages/RpcAnalyzer/RpcAnalyzer")
);
const EditDashboards = React.lazy(() =>
  import("../pages/EditDashboards/EditDashboards")
);
const DashboardPage = React.lazy(() =>
  import("../pages/DashboardPage/DashboardPage")
);
const EditWidgets = React.lazy(() =>
  import("../pages/EditWidgets/EditWidgets")
);
const EditAlerts = React.lazy(() => import("../pages/EditAlerts/EditAlerts"));
const MapView = React.lazy(() => import("../pages/MapView/MapView"));
const MapList = React.lazy(() => import("../pages/MapList/MapList"));

export const routes = [
  // { path: '/', exact: true, name: 'Home', component: RbStudio },

  { path: "/master", exact: true, name: "Home", component: Home },
  {
    path: "/maplist",
    exact: true,
    name: "Map List",
    component: MapList,
  },
  {
    path: "/map/:id",
    exact: true,
    name: "Map View",
    component: MapView,
  },
  // {
  //   path: "/dashboards",
  //   exact: true,
  //   name: "Edit Dashboard",
  //   component: EditDashboards,
  // },
  {
    path: "/dashboard/:slug",
    exact: true,
    name: "Create Dashboard",
    component: DashboardPage,
  },
  {
    path: "/widgets",
    exact: true,
    name: "Edit Widgets",
    component: EditWidgets,
  },
  {
    path: "/Alerts",
    exact: true,
    name: "Edit Alerts",
    component: EditAlerts,
  },
  {
    path: "/dashboards",
    exact: true,
    name: "Manage Dashboard",
    component: ManageDashboard,
  },
  {
    path: "/dashboards/rpc",
    exact: true,
    name: "RpcAnalyzer",
    component: RpcAnalyzer,
  },
  {
    path: "/master/CovidSense",
    exact: true,
    name: "Covid Sense",
    component: MasterHomeCovid,
  },
  {
    path: "/master/AccountAbstract",
    exact: true,
    name: "Account Summary",
    component: AccountAbstract,
  },
  {
    path: "/master/JourneyCountHeatmap",
    exact: true,
    name: "Journey Count Heat map",
    component: JourneyCountHeatmap,
  },
  { path: "/testing", exact: true, name: "Master", component: AreaGraph },
  {
    path: "/master/AllReports",
    exact: true,
    name: "All Reports",
    component: AllReports,
  },
  {
    path: "/master/GlobalSummary",
    exact: true,
    name: "Global Summary",
    component: GlobalSummary,
  },
  {
    path: "/master/StatsCalendar",
    exact: true,
    name: "Stats Calendar Reports",
    component: StatsCalendar,
  },
  /*
  {
    path: '/master/PerformanceDetails',
    exact: true,
    name: 'Performance Details',
    component: PerformanceDetails
  },
  {
    path: '/master/SecurityDetails',
    exact: true,
    name: 'Security Details',
    component: SecurityMain
  },
  {
    path: '/master/ComplianceDetails',
    exact: true,
    name: 'Compliance Details',
    component: ComplianceDetails
  },
  {
    path: '/master/LanePredictionMain',
    exact: true,
    name: 'Lane Prediction Center',
    component: LanePredictionMain
  },
  {
    path: '/master/DocsMain',
    exact: true,
    name: 'Documentations and Help',
    component: HelpAndDoc
  },
  */
  {
    path: "/master/discovery/:tabId",
    exact: true,
    name: "Discover Anything",
    component: DiscoveryHome,
  },
  {
    path: "/master/UserProfile/:tabId",
    exact: true,
    name: "Current User Profile",
    component: UserProfileHome,
  },
  {
    path: "/master/AdminSection/:tabId",
    exact: true,
    name: "Admin Section",
    component: AdminHome,
  },
  {
    path: "/master/Compliance/:tabId",
    exact: true,
    name: "Compliance Home",
    component: ComplianceHome,
  },
  {
    path: "/master/Performance/:tabId",
    exact: true,
    name: "Performance Home",
    component: PerformanceHome,
  },
  {
    path: "/master/Transporters/:tabId",
    exact: true,
    name: "Transporters Home",
    component: TransportersHome,
  },
  {
    path: "/master/Drivers/:tabId",
    exact: true,
    name: "Drivers Home",
    component: DriversHome,
  },
  {
    path: "/master/Partners/:tabId",
    exact: true,
    name: "Partners Home",
    component: PartnersHome,
  },
  {
    path: "/master/Freightage/:tabId",
    exact: true,
    name: "Freightage Home",
    component: FreightageHome,
  },
  {
    path: "/master/Visibility/:tabId",
    exact: true,
    name: "Visibility Home",
    component: VisibilityHome,
  },
  {
    path: "/master/MultiModalVisibility/:tabId",
    exact: true,
    name: "MultiModal Visibility Home",
    component: MultiModalVisibility,
  },
  {
    path: "/master/MultiModalAnalysis/:tabId",
    exact: true,
    name: "MultiModal Analysis Home",
    component: MultiModalAnalysis,
  },
  {
    path: "/master/ColdChain/:tabId",
    exact: true,
    name: "Cold Chain Home",
    component: ColdChainHome,
  },
  {
    path: "/master/ColdChain/Analytics/:tabId",
    exact: true,
    name: "Cold Chain Analytics",
    component: ColdChainAnalyticsHome,
  },
  {
    path: "/master/asset/analysis/:tabId",
    exact: true,
    name: "Asset Analytics",
    component: AssetAnalysisHome,
  },
  {
    path: "/master/asset/visibility/:tabId",
    exact: true,
    name: "Asset Visibility",
    component: AssetVisibilityHome,
  },
  {
    path: "/master/Security/",
    exact: true,
    name: "Security Home",
    component: SecurityMain,
  },
  // {
  //   path: '/master/Compliance/',
  //   exact: true,
  //   name: 'Compliance Home',
  //   component: ComplianceDetails
  // },
  {
    path: "/master/LanePredictionMain",
    exact: true,
    name: "Prediction Home",
    component: LanePredictionMain,
  },
  //   { path: '/users', exact: true, name: 'Users', component: Users },
  //   { path: '/users/:id', exact: true, name: 'User Details', component: User }
];

export default () => {
  return (
    <React.Fragment>
      <Switch>
        {compact(
          map(routes, ({ component: Component, ...routeProps }) => {
            return !isUndefined(Component) ? (
              <Route
                key={`route_${routeProps.path}_${routeProps.name}`}
                {...routeProps}
                render={(props) => (
                  <React.Suspense fallback={<SuspenseLoader />}>
                    <Component {...props} />
                  </React.Suspense>
                )}
              />
            ) : null;
          })
        )}
        {/* <Route render={() => <Redirect to='/master' />} /> */}
      </Switch>
    </React.Fragment>
  );
};
