const initialState = {
  isFetching: true,
  zones: undefined,
  categories: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_ZONES": {
      return {
        ...state,
        isFetching: true,
      };
    }
    case "RECEIVE_ZONES": {
      // if (action.zones.length < 4) {
      //   return {
      //     ...state,
      //     isFetching: false,
      //     zones: [],
      //     categories: [],
      //     parentGrower: [],
      //     parentRetailer: [],
      //   };
      // }
      console.log("zones", action.zones);
      let zones = [];
      let categories = [];
      let parentGrower = [];
      let parentRetailer = [];
      action.zones.map((data) => {
        if (data.viewType === "asset_category_filter") {
          categories.push(data.resultPackage);
        }
        if (data.viewType === "zones") {
          action.zones[0].resultPackage.map((d) => {zones.push(d);});
        }
        if (data.viewType === "zone_parent_2") {
          parentGrower.push(data.resultPackage[0]);
        }
        if (data.viewType === "zone_parent_1") {
          parentRetailer.push(data.resultPackage[0]);
        }
      });
      categories = categories[0];
      return {
        ...state,
        isFetching: false,
        zones,
        categories,
        parentGrower,
        parentRetailer,
      };
    }
    default: {
      return state;
    }
  }
};
