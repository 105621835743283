import { createActions } from "../../utils/helpers";

export const SETTING_APPLICATION_CONTEXT = "APPLICATION_CONTEXT";

export const setComponentContext = (key, value) => async (dispatch) => {
  dispatch(
    createActions(SETTING_APPLICATION_CONTEXT, {
      key,
      value,
    })
  );
};
