import React from "react";
import CustomAside from "../../common/CustomAside";
import AsideContent from "../../containers/appAside/AsideContent";

export default class AppAside extends React.Component {
  render() {
    return (
      <CustomAside
        isOpen={this.props.isOpen}
        className={this.props.className}
        closeAside={this.props.closeRightAsideOpen}
      >
        <AsideContent
          queryText="select distinct account_name from rbdata"
          coreConnected={this.props.coreConnected}
        />
      </CustomAside>
    );
  }
}
