export function getColor(d) {
  const z = d.start[2];
  const r = z / 10000;

  return [255 * (1 - r * 2), 128 * r, 255 * r, 255 * (1 - r)];
}

export function getSize(type) {
  if (type.search("major") >= 0) {
    return 100;
  }
  if (type.search("small") >= 0) {
    return 30;
  }
  return 60;
}

export function getTooltip({ object }) {
  return (
    object &&
    `\
    ${object.country || object.abbrev || ""}
    ${object.name.indexOf("0x") >= 0 ? "" : object.name}`
  );
}

export const INITIAL_VIEW_STATE = {
  latitude: 47.65,
  longitude: 7,
  zoom: 5,
  maxZoom: 16,
  pitch: 0,
  bearing: 0,
};

export const MAP_STYLE =
  "https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json";

export const rpcDashboardCartStyle = {};
export const API_URL = process.env.REACT_APP_API_URL || "";

export const MAP_BOX_TOKEN = process.env.REACT_APP_MAP_BOX_TOKEN || "";

export const PROCESSINGQUERY_REQUEST_TYPES = {
  NLQ: "ProcessNLQ",
  QUERY: "ProcessQuery",
  CMPSMRY: "CompleteSummary",
  LPSNP: "LanePredictionSnap",
};

export const MAPBOX_MAP_STYLES = {
  STREET: "streets-v11",
  DARK: "dark-v10",
  LIGHT: "light-v10",
  SATELLITE: "satellite-v9",
  OUTDOOR: "outdoors-v11",
};

export const MAP_TYPES_OPTIONS = [
  {
    label: "Street",
    value: MAPBOX_MAP_STYLES.STREET,
  },
  {
    label: "Dark",
    value: MAPBOX_MAP_STYLES.DARK,
  },
  {
    label: "Light",
    value: MAPBOX_MAP_STYLES.LIGHT,
  },
  {
    label: "Satellite",
    value: MAPBOX_MAP_STYLES.SATELLITE,
  },
  {
    label: "Outdoor",
    value: MAPBOX_MAP_STYLES.OUTDOOR,
  },
];

export const filterOption = {
  Transport_Modes: {
    values: ["Ocean Mode", "Flight Mode", "Land Mode"],
  },
  Lane_Filters: {
    values: ["Transporters", "Stopages", "ETA", "Origins", "Destinations"],
  },
};

export const Months = {
  0: 31,
  1: 28,
  2: 31,
  3: 30,
  4: 31,
  5: 30,
  6: 31,
  7: 31,
  8: 30,
  9: 31,
  10: 30,
  11: 31,
};

export const calendarStatusList = [
  { label: "Journeys arrived", key: "total_journeys_arrived", type: "arrived" },
  {
    label: "Journeys departed",
    key: "total_journeys_departed",
    type: "departed",
  },
  { label: "Journeys late", key: "total_journeys_late", type: "late" },
  { label: "Journeys ontime", key: "total_journeys_ontime", type: "ontime" },
  {
    label: "Journeys terminated",
    key: "total_journeys_terminated",
    type: "terminated",
  },
];

export const calendarStatusColor = {
  arrived: "",
  departed: "#f79411",
  late: "",
  ontime: "",
  terminated: "",
};

export const countryList = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua &amp; Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia &amp; Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote D Ivoire",
  "Croatia",
  "Cruise Ship",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Polynesia",
  "French West Indies",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kuwait",
  "Kyrgyz Republic",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Pierre &amp; Miquelon",
  "Samoa",
  "San Marino",
  "Satellite",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "St Kitts &amp; Nevis",
  "St Lucia",
  "St Vincent",
  "St. Lucia",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor L'Este",
  "Togo",
  "Tonga",
  "Trinidad &amp; Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks &amp; Caicos",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "Uruguay",
  "Uzbekistan",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (US)",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

// Should be same as MasterHome.jsx Optionkey
export const dataOptionKey = {
  pageId: "Master",
  pageSubId: "Home",
  requestType: "all",
  viewData: [
    {
      viewType: "item_list",
      dataTypes: [
        { header: "Origins", fields: ["OriginName"] },
        { header: "Destinations", fields: ["DestinationName"] },
      ],
    },
    {
      viewType: "common_map_data",
      dataTypes: {
        header: "All Lanes",
        fields: ["LaneName", "OriginName", "DestinationName", "TotalJourneys"],
      },
    },
    {
      viewType: "item_list_value",
      dataTypes: [
        { header: "AllLanes", fields: ["lane_name", "journey_count"] },
      ],
    },
    {
      viewType: "data_collection",
      dataTypes: [
        { header: "AccountData", fields: ["lane_name", "journey_count"] },
      ],
    },
    {
      viewType: "periodic_timeline_collection",
      dataTypes: [
        {
          header: "Timeline",
          fields: ["Date", "JourneysType"],
        },
      ],
    },
  ],
  pageFilters: {
    laneInfo: {
      values: [],
    },
    origin: {
      values: [],
    },
    destination: {
      values: [],
    },
    viewFilters: {
      values: [],
    },
  },
};

export const quarters = ["Q1", "Q2", "Q3", "Q4"];
export const styles = {
  card: {
    backgroundColor: "white",
    height: "95%",
    flex: 1,
    padding: "1em",
    borderRadius: "1em",
    // margin: "10px 10px",
  },
  rpcDashboardCard: {
    backgroundColor: "white",
    height: "95%",
    flex: 1,
    padding: "1em",
    borderRadius: "1em",
    minHeight: 400,
    margin: "1em 0em",
    display: "flex",
    flexDirection: "column",
  },
};

export const chartColors = [
  "rgba(0, 122, 255, 1)",
  "rgba(52, 199, 199, 1)",
  "rgba(88, 86, 214, 1)",
  "rgba(255, 149, 0, 1)",
  "rgba(255, 45, 85, 1)",
  "rgba(175, 82, 222, 1)",
  "rgba(255, 59, 48, 1)",
  "rgba(90, 200, 250, 1)",
  "rgba(255, 204, 0, 1)",
];

export const journeyPageSteps = [
  {
    selector: ".first-step",
    content:
      "In this dashboard, Journey compliance data is visualized based on selected period.",
  },
  {
    selector: ".second-step",
    content: "Pick a range here",
  },
  {
    selector: ".third-step",
    content: "Select time span here",
  },
  {
    selector: ".fourth-step",
    content: "Select compliance factor here",
  },
  {
    selector: ".fifth-step",
    content: `Click on a bar to get more compliance types' data`,
  },
];

export const HomePageSteps = [
  {
    selector: ".home-first-step",
    content:
      "In this Page, we get a summarized view of Shipment performance and Key indicators, based on selected period.",
  },
  {
    selector: ".home-second-step",
    content: "Pick a range here",
  },
  {
    selector: ".home-third-step",
    content: "Select a Lane performance factor here.",
  },
  {
    selector: ".home-fourth-step",
    content:
      "Click on the progress bars to toggle between percentage and actual values.",
  },
  {
    selector: ".home-fifth-step",
    content: `You can make the map bigger by clicking on the collapse button.`,
  },
];

export const TripsPageSteps = [
  {
    selector: ".trips-first-step",
    content: `Analysis of RPC Trips or looped journeys (start and end at same location/location type) based on facility, category and individual cycle time.`,
  },
  {
    selector: ".trips-second-step",
    content: `The buttons at the top of the charts help in filtering based on RPC Category, Grower name, Trip Origin/Destination, Trip category and the time 
    period during which the trip might have take place. Click on one or multiple retailers to to view the RPC 
    trip metrics for the selected retailers. You can also use the drop-down arrows to select specific supply regions for that retailer.`,
  },
  {
    selector: ".trips-third-step",
    content: `If RPC is missing for 90 days then it will be considered as ‘Lost RPC’ and out of those Lost RPCs if battery remaining days is less than 3 days then those RPCs will be called as ‘Low Battery RPC’`,
  },
];
