import React from "react";

const FilterContext = React.createContext({});

export const filterContext = (Component) => {
  return (props) => (
    <FilterContext.Consumer>
      {(contextProps) => {
        return <Component {...contextProps} {...props} />;
      }}
    </FilterContext.Consumer>
  );
};

export default class CurrentFilterContext extends React.Component {
  state = {
    currentFilter: {},
  };

  componentDidMount() {
    if (!this.state.currentFilter) {
      this.setCurrentFilter();
    }
  }

  render() {
    return (
      <FilterContext.Provider
        value={{
          currentFilter: this.state.currentFilter,
          setCurrentFilter: this.setCurrentFilter,
          setGeoLocationContext: this.setGeoLocationContext,
          setCountryContext: this.setCountryContext,
        }}
      >
        {this.props.children}
      </FilterContext.Provider>
    );
  }

  removeFilter = (key, value) => {
    let { currentFilter } = this.state;
    delete currentFilter[key][value];
    this.setState({
      currentFilter: currentFilter,
    });
  };

  addFilter = (key, value) => {
    let { currentFilter } = this.state;
    if (!currentFilter[key]) {
      currentFilter[key] = {};
    }
    currentFilter[key][value] = true;
    this.setState({
      currentFilter: currentFilter,
    });
  };

  setGeoLocationContext = (value) => {
    let { currentFilter } = this.state;
    currentFilter["geoDistanceValue"] = value;
    this.setState({
      currentFilter: currentFilter,
    });
  };

  setCountryContext = (country) => {
    console.log("setting country");
    let { currentFilter } = this.state;
    currentFilter["Geo_Limits"] = {};
    currentFilter["Geo_Limits"]["Within Country"] = country;
    this.setState({
      currentFilter: currentFilter,
    });
  };

  setCurrentFilter = (currentFilter, value) => {
    if (this.state.currentFilter) {
      this.state.currentFilter[currentFilter] &&
      this.state.currentFilter[currentFilter][value]
        ? this.removeFilter(currentFilter, value)
        : this.addFilter(currentFilter, value);
    }
  };
}
