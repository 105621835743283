import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./CustomAside.css";

const bodyTag = document.getElementById("htmlBody");

export const DIRECTION_TYPES = {
  RIGHT: "right",
  LEFT: "left",
};

export default class CustomAside extends React.Component {
  static propTypes = {
    direction: PropTypes.oneOf(Object.values(DIRECTION_TYPES)),
  };

  static defaultProps = {
    direction: DIRECTION_TYPES.RIGHT,
  };

  static getDerivedStateFromProps(nextProps) {
    const stateObj = {};
    if (nextProps.isOpen) {
      bodyTag.classList.add("overflow-hidden");
    } else {
      bodyTag.classList.remove("overflow-hidden");
    }

    return stateObj;
  }

  state = {};

  render() {
    return (
      <React.Fragment>
        <div
          className={classNames("aside-overlay", this.props.overlayClassName, {
            show: this.props.isOpen,
          })}
          onClick={this.props.closeAside}
        />
        <aside
          className={classNames(
            "aside-menu fixed",
            this.props.direction,
            this.props.className,
            {
              [`${
                this.props.direction === DIRECTION_TYPES.RIGHT ? "mr-0" : "ml-0"
              }`]: this.props.isOpen,
            }
          )}
        >
          {this.props.children}
        </aside>
      </React.Fragment>
    );
  }
}
