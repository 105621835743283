/* eslint-disable */
import { AppSwitch } from "@coreui/react";
import {
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ContactUsForm from "../containers/login/ContactUsForm";
import LoginForm from "../containers/login/LoginForm";
import "./Login.scss";

let randomNumberForTexts = Math.floor(Math.random() * (5 - 0) + 0);

export default class Login extends React.Component {
  state = {
    contactUsModalOpen: false,
    isUrgent: false,
    optionKey: {
      pageId: "User",
      pageSubId: "Login",
      requestType: "all",
      viewData: [
        {
          viewType: "map_path_collection",
          dataTypes: {
            header: "LanesAndLocations",
            fields: [
              "LaneName",
              "OriginName",
              "DestinationName",
              "TotalJourneys",
            ],
          },
        },
      ],
      pageFilters: {
        loginInfo: {
          values: [],
        },
        contactInfo: {
          values: [],
        },
      },
    },
  };

  contactUsFormRef = React.createRef();

  render() {
    return (
      <div className="container-login">
        <div className="left-side">
          <div className="top-bar">
            <a href="https://www.roambee.com/" className="hvr-pop">
              <img
                src="assets/images/roambee-loader.png"
                alt=""
                srcset=""
                className="logo"
              />
            </a>
          </div>
          <div className="body">
            <LoginForm optionKey={this.state.optionKey} />
          </div>
          <div className="footer">
            <p>
              © {new Date().getFullYear()} Roambee Corporation. All rights
              reserved.
            </p>
          </div>
        </div>

        <div
          className="right-side"
          // style={{background: `linear-gradient(rgba(3, 14, 61, 0.7), rgba(3, 14, 61, 0.7)), url(/loginPage/${randomNumberForTexts}-min.jpeg)`}}
        >
          <div className="top-bar">
            <div>
              <button
                color="primary"
                onClick={() =>
                  window.open(
                    `https://www.roambee.com/request-a-roambee-trial/`
                  )
                }
                className="ui-button-rounded ui-button-warning ui-button-raised"
                id="sign-in-button"
                type="button"
                style={{
                  border: "1px solid grey",
                  height: "34px",
                  marginRight: "5px",
                }}
              >
                Request a Trial
              </button>
            </div>
          </div>
          <div className="body">
            <div className="section-1 white">
              <p>{leftSideTexts[randomNumberForTexts].textTop}</p>
              <h2>{leftSideTexts[randomNumberForTexts].textBottom}</h2>
            </div>
            <div className="section-2 white">
              <div className="social-buttons">
                <a
                  className="hvr-pop"
                  style={{ color: "white" }}
                  href="https://twitter.com/roam_bee"
                >
                  <FontAwesomeIcon
                    size="2x"
                    icon={faTwitter}
                    className="ml-1"
                    onClick={this.resetSelection}
                  />
                </a>
                <a
                  className="hvr-pop"
                  style={{ color: "white" }}
                  href="https://www.linkedin.com/company/roambee/"
                >
                  <FontAwesomeIcon
                    size="2x"
                    icon={faLinkedinIn}
                    className="ml-1"
                    onClick={this.resetSelection}
                  />
                </a>
                <a
                  className="hvr-pop"
                  style={{ color: "white" }}
                  href="https://www.youtube.com/channel/UCJsDFbzJ4dMoFCgPNz8NtjQ"
                >
                  <FontAwesomeIcon
                    size="2x"
                    icon={faYoutube}
                    className="ml-1"
                    onClick={this.resetSelection}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    // return (
    //   <React.Fragment>
    //     <div className='flex-fill h-100 w-100 d-flex'>
    //       <div className='flex-fill position-relative'>
    //         <CustomDeckGl
    //           height='100%'
    //           width='100%'
    //           mapType={MAPBOX_MAP_STYLES.DARK}
    //         />
    //       </div>
    //       <div className='p-3 d-flex align-items-center justify-content-center flex-column'>
    //         <LoginForm
    //           optionKey={this.state.optionKey}
    //         />
    //         <Button
    //           className='mt-2'
    //           color='primary'
    //           block
    //           onClick={this.toggleContactUsModal}
    //         >
    //           Contact Us
    //         </Button>
    //       </div>
    //     </div>
    //     {this.renderContactUsModal()}
    //   </React.Fragment>
    // );
  }

  renderContactUsModal = () => (
    <Modal
      isOpen={this.state.contactUsModalOpen}
      toggle={this.toggleContactUsModal}
      size="lg"
      className="modal-half-bottom"
      centered
    >
      <ModalHeader toggle={this.props.closeModal}>
        Contact Us
        <br />
        <small>
          Please provide your email, so we can reach out to you with your
          request.
        </small>
      </ModalHeader>
      <ModalBody className="overflow-auto">
        <ContactUsForm
          ref={this.contactUsFormRef}
          OptionKey={this.state.optionKey}
          isUrgent={this.state.isUrgent}
        />
      </ModalBody>
      <ModalFooter className="d-flex align-items-center justify-content-between">
        <small>
          <b>Note:</b> We do not share your contact information to any one. Your
          contact information is only used to contact back you as if needed.
        </small>
        <div className="d-flex align-items-center justify-content-center">
          <span className="mr-2">
            <small>Is Urgent? </small>
            <br />
            <AppSwitch
              dataOn={"Yes"}
              dataOff={"No"}
              label
              color={"info"}
              outline={"alt"}
              size={"sm"}
              checked={this.state.isUrgent}
              onChange={() => {
                this.setState({ isUrgent: !this.state.isUrgent });
              }}
            />
          </span>
          <Button onClick={this.submitForm}>Submit</Button>
        </div>
      </ModalFooter>
    </Modal>
  );

  toggleContactUsModal = () =>
    this.setState({ contactUsModalOpen: !this.state.contactUsModalOpen });

  submitContactUsForm = async () => {
    const loginResp = await this.contactUsFormRef.current.onSubmit();
  };
}

const leftSideTexts = [
  {
    textTop:
      "FDA compliance with on-time delivery, reduced liability, and actionable cold chain management",
    textBottom: "Roambee Puts Pharma Supply Chains in Good Health",
  },
  {
    textTop:
      "Why wait days to find a copper drum in the yard when it can be done in minutes",
    textBottom: "Better Asset Utilization & Security - In-Yard or In-Field",
  },
  {
    textTop:
      "Cost effective monitoring of pallets and cases - in the warehouse and in-transit",
    textBottom: "The BeeBeacons took visibility down to a package-level",
  },
  {
    textTop: `Enabling enterprises to "act in time" to salvage goods - no USB cables, no waiting for data uploads.`,
    textBottom: "Driving a Paradigm Shift in Cold Chain Monitoring",
  },
  {
    textTop:
      "On-time delivery of finished vehicles to yard efficiency to bin management to spare parts monitoring - we do it all!",
    textBottom: "Roambee Puts Automotive Supply Chains into Cruise Control",
  },
  {
    textTop: "Because... Roambee Puts Them in Control",
    textBottom: "Hundreds of Enterprises Around the World Run on Roambee",
  },
];
