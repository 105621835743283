import { AppSwitch } from "@coreui/react";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import BugReportform from "../../containers/bugReport/BugReportForm";

class BugReportModal extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    onAfterOpen: PropTypes.func,
    closeModal: PropTypes.func,
  };

  static defaultProps = {
    open: false,
  };

  state = {
    isUrgent: false,
    bugReportKey: "",
    OptionKey: {
      pageId: "Master",
      pageSubId: "SubmitBug",
      requestType: "all",
      viewData: [
        {
          viewType: "user_form",
          dataTypes: [{ header: "Response", fields: ["HelpContent"] }],
        },
      ],
      pageFilters: {
        bugContext: {
          values: [] /* {this.props.location.pathname} will be added into values array before sending to backend*/,
        },
        pageContext: {
          values: [],
        },
      },
    },
  };

  formRef = React.createRef();

  render() {
    return (
      <Modal
        isOpen={this.props.open}
        toggle={this.props.closeModal}
        onOpened={this.props.onAfterOpen}
        size="lg"
        className="modal-half-bottom"
        centered
      >
        <ModalHeader toggle={this.props.closeModal}>
          Submit Bug
          <br />
          <small>
            Please provide your email (optional), so we can reach out to you
            with more update with regard to your bug/problem.
          </small>
        </ModalHeader>
        <ModalBody className="overflow-auto">
          <Row>
            <Col md={12}>
              <span className="text-info mr-1">Bug Context:</span>
              <span>{this.props.location.pathname}</span>
            </Col>
          </Row>
          <BugReportform
            bugReportKey={this.state.bugReportKey}
            ref={this.formRef}
            isUrgent={this.state.isUrgent}
            OptionKey={this.state.OptionKey}
            pathname={this.props.location.pathname}
            setOptionKey={this.setOptionKey}
          />
        </ModalBody>
        <ModalFooter className="d-flex align-items-center justify-content-between">
          <small>
            <b>Note:</b> We do not share your contact information to any one.
            Your contact information is only used to contact back you as if
            needed.
          </small>
          <div className="d-flex align-items-center justify-content-center">
            <span className="mr-2">
              <small>Is Urgent? </small>
              <br />
              <AppSwitch
                dataOn={"Yes"}
                dataOff={"No"}
                label
                color={"info"}
                outline={"alt"}
                size={"sm"}
                checked={this.state.isUrgent}
                onChange={() => {
                  this.setState({ isUrgent: !this.state.isUrgent });
                }}
              />
            </span>
            <Button onClick={this.submitForm}>Submit</Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }

  submitForm = async () => {
    const resultData = await this.formRef.current.onSubmit();
    this.props.closeModal();
    const message = get(resultData, "0.resultPackage.values.message");

    toast(message, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  };

  setOptionKey = (key, value) => {
    this.setState({
      [key]: value,
    });
  };
}

export default withRouter(BugReportModal);
