import get from "lodash/get";
import { connect } from "react-redux";
import { queryProcessingIn as queryProcessingInAction } from "../../../state/actions/coreAPI";
import { generateQueryProsKey } from "../../../utils/helpers";
import BugReportForm from "../../partials/bugReport/BugReportForm";

export function mapStateToProps(state, ownProps) {
  const queryKey = `processingQueryIn.${generateQueryProsKey(
    "",
    ownProps.bugReportKey,
    "ComponentRequest",
    "",
    undefined,
    ""
  )}`;
  return {
    data: get(state.appData, queryKey) || [],
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    submitBugReport: async (optionKey) => {
      return await dispatch(
        queryProcessingInAction(
          "",
          optionKey,
          "ComponentRequest",
          "",
          undefined,
          ""
        )
      );
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(BugReportForm);
