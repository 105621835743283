import React from "react";

var d = new Date();
d.setMonth(d.getMonth() - 3);
const start = d;
start.setTime(start);

const MenusContext = React.createContext({
  isDark: false,
  isFullscreen: false,
  isSideBarHidden:
    localStorage.getItem("isSideBarHidden") === null
      ? false
      : localStorage.getItem("isSideBarHidden") === "true"
      ? true
      : false,
  selectedDateRange: { start: start, end: new Date() },
  menus: [
    // {
    //   title: "Insight",
    //   name: "Insight",
    //   link: "/insight",
    //   subMenu: [
    //     { id: 1, label: "Dashboards", link: "/dashboards", icon: "dashboard" },
    //     {
    //       id: 2,
    //       label: "Widgets",
    //       link: "/widgets",
    //     },
    //     { id: 3, label: "Alerts", link: "/alerts" },
    //   ],
    // },
    // {
    //   title: "Lane Performance",
    //   name: "Lane Performance",
    //   link: "/master/Performance/Snapshot",
    //   subMenu: [
    //     {
    //       id: 1,
    //       label: "Snapshot",
    //       link: "/master/Performance/Snapshot",
    //       icon: "home",
    //     },
    //     {
    //       id: 2,
    //       label: "Distribution",
    //       link: "/master/Performance/Distribution",
    //     },
    //     {
    //       id: 3,
    //       label: "Location",
    //       link: "/master/Performance/Locations",
    //     },
    //     {
    //       id: 4,
    //       label: "Punctuality",
    //       link: "/master/Performance/Punctuality",
    //     },
    //     { id: 5, label: "Stoppage", link: "/master/Performance/Stoppage" },
    //     {
    //       id: 6,
    //       label: "Transport Modes",
    //       link: "/master/Performance/TransportModes",
    //     },
    //   ],
    // },
    // {
    //   title: "Lane Visibility",
    //   name: "Lane Visibility",
    //   link: "/master/Visibility/LaneSight",
    //   subMenu: [
    //     {
    //       id: 1,
    //       label: "Lane Sight",
    //       link: "/master/Visibility/LaneSight",
    //       icon: "home",
    //     },
    //     {
    //       id: 2,
    //       label: "Lane Comparison",
    //       link: "/master/visibility/LaneComparison",
    //     },
    //     { id: 3, label: "Lane Score", link: "/master/visibility/LaneScore" },
    //     {
    //       id: 4,
    //       label: "Stoppage Visibility",
    //       link: "/master/visibility/StoppageVisibility",
    //     },
    //     {
    //       id: 5,
    //       label: "Speed Vs Stoppage",
    //       link: "/master/visibility/SpeedStoppage",
    //     },
    //   ],
    // },
    // {
    //   title: "Multi-Modal Logistics",
    //   name: "Multi-Modal Logistics",
    //   link: "/master/MultiModalAnalysis/SpeedLandAirOcean",
    //   subMenu: [
    //     {
    //       id: 1,
    //       label: "Speed (Land, Air & Ocean)",
    //       link: "/master/MultiModalAnalysis/SpeedLandAirOcean",
    //       icon: "home",
    //     },
    //     {
    //       id: 2,
    //       label: "Transit & Dwell Time",
    //       link: "/master/MultiModalAnalysis/TransitDwellTime",
    //     },
    //     {
    //       id: 3,
    //       label: "Distance & Time",
    //       link: "/master/MultiModalAnalysis/DistanceTime",
    //     },
    //     {
    //       id: 4,
    //       label: "Distribution",
    //       link: "/master/MultiModalAnalysis/Distribution",
    //     },
    //   ],
    // },
    // {
    //   title: "Cold Chain",
    //   name: "Cold Chain",
    //   link: "/master/ColdChain/Tracking",
    //   subMenu: [
    //     {
    //       isGroup: true,
    //       label: "Visibility",
    //       groupItems: [
    //         {
    //           id: 1,
    //           label: "Temperature Tracking",
    //           link: "/master/ColdChain/Tracking",
    //           icon: "home",
    //         },
    //         {
    //           id: 2,
    //           label: "MKT Analysis",
    //           link: "/master/ColdChain/MKTAnalysis",
    //         },
    //         {
    //           id: 3,
    //           label: "Lane Comparison",
    //           link: "/master/ColdChain/Comparison",
    //         },
    //         {
    //           id: 4,
    //           label: "Lane Score",
    //           link: "/master/ColdChain/Score",
    //         },
    //         {
    //           id: 5,
    //           label: "Transporter Performance",
    //           link: "/master/ColdChain/Performance",
    //         },
    //       ],
    //     },
    //     {
    //       isGroup: true,
    //       label: "Analysis",
    //       groupItems: [
    //         {
    //           id: 6,
    //           label: "Shipments",
    //           link: "/master/ColdChain/Analytics/Shipments",
    //           icon: "home",
    //         },
    //         {
    //           id: 7,
    //           label: "Transitions",
    //           link: "/master/ColdChain/Analytics/Transitions",
    //         },
    //         {
    //           id: 8,
    //           label: "Range",
    //           link: "/master/ColdChain/Analytics/Range",
    //         },
    //         {
    //           id: 9,
    //           label: "Transporter Violations",
    //           link: "/master/ColdChain/Analytics/Violations",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   title: "Transporters",
    //   name: "Transporters",
    //   link: "/master/Transporters/Summary",
    //   subMenu: [
    //     {
    //       id: 1,
    //       label: "Summary",
    //       link: "/master/Transporters/Summary",
    //       icon: "home",
    //     },
    //     {
    //       id: 2,
    //       label: "Analysis",
    //       link: "/master/Transporters/Analysis",
    //     },
    //     {
    //       id: 3,
    //       label: "Comparision",
    //       link: "/master/Transporters/TransportersComparison",
    //     },
    //     {
    //       id: 4,
    //       label: "JourneyMap",
    //       link: "/master/Transporters/TransportersJourneyMap",
    //     },
    //   ],
    // },
    // {
    //   title: "Drivers",
    //   name: "Drivers",
    //   link: "/master/Drivers/Summary",
    //   subMenu: [
    //     {
    //       id: 1,
    //       label: "Summary",
    //       link: "/master/Drivers/Summary",
    //       icon: "home",
    //     },
    //     {
    //       id: 2,
    //       label: "Analysis",
    //       link: "/master/Drivers/Analysis",
    //     },
    //     {
    //       id: 3,
    //       label: "Comparision",
    //       link: "/master/Drivers/DriversComparison",
    //     },
    //     {
    //       id: 4,
    //       label: "JourneyMap",
    //       link: "/master/Drivers/DriversJourneyMap",
    //     },
    //   ],
    // },
    // {
    //   title: "Partners",
    //   name: "Partners",
    //   link: "/master/Partners/Summary",
    //   subMenu: [
    //     {
    //       id: 1,
    //       label: "Summary",
    //       link: "/master/Partners/Summary",
    //       icon: "home",
    //     },
    //     {
    //       id: 2,
    //       label: "Analysis",
    //       link: "/master/Partners/Analysis",
    //     },
    //     {
    //       id: 3,
    //       label: "Comparision",
    //       link: "/master/Partners/PartnersComparison",
    //     },
    //     {
    //       id: 4,
    //       label: "JourneyMap",
    //       link: "/master/Partners/PartnersJourneyMap",
    //     },
    //   ],
    // },
    // {
    //   title: "AssetManagement",
    //   name: "AssetManagement",
    //   link: "/master/asset/visibility/AssetSummary",
    //   subMenu: [
    //     {
    //       id: 1,
    //       label: "Asset Summary",
    //       link: "/master/asset/visibility/AssetSummary",
    //       icon: "home",
    //     },
    //     {
    //       id: 2,
    //       label: "Dwell Time Visibility",
    //       link: "/master/asset/visibility/DwellTimeVisibility",
    //     },
    //     {
    //       id: 3,
    //       label: "Asset Cycles",
    //       link: "/master/asset/visibility/AssetCycles",
    //     },
    //   ],
    // },
    // {
    //   title: "Security & Compiance",
    //   name: "Security & Compiance",
    //   link: "/master/Compliance/Journey",
    //   subMenu: [
    //     {
    //       id: 1,
    //       label: "Journey Compliance",
    //       link: "/master/Compliance/Journey",
    //       icon: "home",
    //     },
    //   ],
    // },
  ],
});

export default MenusContext;
