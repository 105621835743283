import { AppSwitch } from "@coreui/react";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ContactUsForm from "../../containers/contactUs/ContactUsForm";

class ContactUsModal extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    onAfterOpen: PropTypes.func,
    closeModal: PropTypes.func,
  };

  static defaultProps = {
    open: false,
  };

  state = {
    isUrgent: false,
    OptionKey: {
      pageId: "Master",
      pageSubId: "ContactUs",
      requestType: "all",
      viewData: [
        {
          viewType: "user_form",
          dataTypes: [{ header: "Response", fields: ["HelpContent"] }],
        },
      ],
      pageFilters: {
        helpContext: {
          values: [] /* {this.props.location.pathname} will be added into values array before sending to backend*/,
        },
        pageContext: {
          values: [],
        },
      },
    },
  };

  formRef = React.createRef();

  render() {
    return (
      <Modal
        isOpen={this.props.open}
        toggle={this.props.closeModal}
        onOpened={this.props.onAfterOpen}
        size="lg"
        className="modal-half-bottom"
        centered
      >
        <ModalHeader toggle={this.props.closeModal}>
          Contact Us
          <br />
          <small>
            Please provide your email, so we can reach out to you with your
            request.
          </small>
        </ModalHeader>
        <ModalBody className="overflow-auto">
          <ContactUsForm
            ref={this.formRef}
            OptionKey={this.state.OptionKey}
            isUrgent={this.state.isUrgent}
            pathname={this.props.location.pathname}
          />
        </ModalBody>
        <ModalFooter className="d-flex align-items-center justify-content-between">
          <small>
            <b>Note:</b> We do not share your contact information to any one.
            Your contact information is only used to contact back you as if
            needed.
          </small>
          <div className="d-flex align-items-center justify-content-center">
            <span className="mr-2">
              <small>Is Urgent? </small>
              <br />
              <AppSwitch
                dataOn={"Yes"}
                dataOff={"No"}
                label
                color={"info"}
                outline={"alt"}
                size={"sm"}
                checked={this.state.isUrgent}
                onChange={() => {
                  this.setState({ isUrgent: !this.state.isUrgent });
                }}
              />
            </span>
            <Button onClick={this.submitForm}>Submit</Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }

  submitForm = () => {
    this.formRef.current.onSubmit();
  };
}

export default withRouter(ContactUsModal);
