import get from "lodash/get";
import { connect } from "react-redux";
import SettingsAndStatuses from "../../partials/appAside/SettingsAndStatuses";

function mapStateToProps(state, ownProps) {
  return {
    coreConnected: get(state, "appData.coreConnected") || false,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsAndStatuses);
