import classNames from "classnames";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import AsideSelectedAccounts from "../../containers/appAside/AsideSelectedAccounts";
import SettingsAndStatuses from "../../containers/appAside/SettingsAndStatuses";
import { currentUser } from "../SessionContext";

class DefaultAside extends Component {
  static defaultProps = {};

  static propTypes = {
    children: PropTypes.node,
  };

  static getDerivedStateFromProps(nextProps) {
    const stateObj = {
      queryResultObj: get(nextProps, "data"),
      queryResultData: get(nextProps, "data.data"),
      selectedCustomer: get(nextProps, "currentUser.accountName") || "",
    };

    return stateObj;
  }

  state = {
    activeTab: 1,
    queryText: "", //select distinct account_name from rbdata',
    queryResultObj: [],
    queryResultData: [],
    selectedCustomer: "None",
    optionKey: JSON.stringify({
      db_specification: "AC",
      fields: {
        field_clause: "NONE",
        all_fields_list: ["ACCOUNT_UUID", "ACCOUNT_NAME"],
      },
    }),
  };

  componentDidMount() {
    // this.props.getQueryProcessingIn(this.state.queryText, this.state.optionKey);
  }

  render() {
    return (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classNames({ active: this.state.activeTab === 1 })}
              onClick={() => {
                this.toggle(1);
              }}
            >
              <i className="icon-list"></i>
            </NavLink>
          </NavItem>
          {/*  BLOCKED BY V1 Release */}
          {/* <NavItem>
            <NavLink
              className={classNames({ active: this.state.activeTab === 2 })}
              onClick={() => {
                this.toggle(2);
              }}>
              <i className='icon-speech'></i>
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              className={classNames({ active: this.state.activeTab === 2 })}
              onClick={() => {
                this.toggle(2);
              }}
            >
              <i className="icon-settings"></i>
            </NavLink>
          </NavItem>
          {/*  BLOCKED BY V1 Release */}
          {/* <NavItem>
            <NavLink
              className={classNames({ active: this.state.activeTab === 4 })}
              onClick={() => {
                this.toggle(4);
              }}>
              <i className='icon-chemistry'></i>
            </NavLink>
          </NavItem> */}
        </Nav>
        <TabContent activeTab={this.state.activeTab} className="m-0 p-0">
          <TabPane tabId={1} className="m-0 p-0">
            <Card className="m-0 p-0">
              <CardHeader>
                <h5>Selected Account</h5>
                {this.state.selectedCustomer}
              </CardHeader>
              <CardBody
                className="ml-1 mt-1 mb-1 mr-1 p-0"
                style={{
                  maxHeight: 600,
                  height: "750px",
                  overflowY: "auto",
                  overflow: "-moz-scrollbars-horizontal",
                }}
              >
                <AsideSelectedAccounts
                  queryText={this.state.queryText}
                  selectedCustomer={this.state.selectedCustomer}
                  onSelect={(accountName) => {
                    this.props.setCurrentUser(accountName);
                  }}
                  optionKey={this.state.optionKey}
                />
              </CardBody>
              <CardFooter className="m-0 p-0">
                <small>
                  Please select any account above to change your current
                  account.
                </small>
              </CardFooter>
            </Card>
          </TabPane>
          {/*  BLOCKED BY V1 Release */}
          {/* <TabPane tabId={2} className='p-3'>
            <h6>Notifications</h6>
          </TabPane> */}
          <TabPane tabId={2} className="p-3">
            <SettingsAndStatuses />
          </TabPane>
          {/*  BLOCKED BY V1 Release */}
          {/* <TabPane tabId={4} className='p-3'>
            <h6>Experiments</h6>
            <hr />
            <ListGroup>
              <ListGroupItem>
                <a href='/dashboard'>Dashboard</a>
              </ListGroupItem>
              <ListGroupItem>
                <a href='/dashboard'>Kepler UI</a>
              </ListGroupItem>
            </ListGroup>
          </TabPane> */}
        </TabContent>
      </React.Fragment>
    );
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  getProcessQueryIn = () => {
    this.props.getQueryProcessingIn(this.state.queryText, this.state.optionKey);
  };
}

export default currentUser(DefaultAside);
