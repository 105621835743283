const initalState = {
  nlqQueryString: "show all lanes",
  nlqOptionKey: {
    pageId: "SearchBar",
    pageSubId: "PageLoad",
    requestType: "all" /* When search query is used 'nlqRequest' */,
    // masterFilter: this.props.currentFilter,
    viewData: [
      {
        viewType: "data_collection",
        dataTypes: [
          "TotalLanes",
          "TotalJourneys",
          "TotalOrigins",
          "TotalDestinations",
          "FirstJourneyStartDate",
          "LastJourneyCompletedDate",
          "ActiveBusinessDays",
          "LastJourneyStartDate",
        ],
      },
      // {
      // 	viewType: "item_list",
      // 	dataTypes: [
      // 		{ header: "Origins", fields: ["OriginName"] },
      // 		{ header: "Destinations", fields: ["DestinationName"] }
      // 	]
      // },
      {
        viewType: "lanes_list",
        dataTypes: ["LaneName", "OriginName", "DestinationName"],
      },
      {
        viewType: "notification_list",
        dataTypes: [
          {
            header: "Alerts",
            fields: ["Compliance", "Performance", "Security", "General"],
          },
        ],
      },
      // {
      // 	viewType: "timeline_collection",
      // 	dataTypes: [
      // 		{
      // 			header: "Timeline",
      // 			fields: ["Date", "JourneysType", "ArrivalStatus"]
      // 		}
      // 	]
      // }
    ],
  },
};

export default (state = initalState, action) => {
  switch (action.type) {
    case "APPLICATION_CONTEXT": {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    }
    default: {
      return state;
    }
  }
};
