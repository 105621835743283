import get from "lodash/get";
import { connect } from "react-redux";
import { PROCESSINGQUERY_REQUEST_TYPES } from "../../../constants";
import { generateQueryProsKey } from "../../../utils/helpers";
import AsideSelectedAccounts from "../../partials/appAside/AsideSelectedAccounts";

function mapStateToProps(state, ownProps) {
  const queryKey = `processingQueryIn.${generateQueryProsKey(
    ownProps.queryText,
    ownProps.optionKey,
    "ProcessQuery",
    "sql",
    "",
    "GUID"
  )}`;

  const dataKey = `processingQueryIn.${generateQueryProsKey(
    state.appContext.nlqQueryString,
    JSON.stringify(state.appContext.nlqOptionKey),
    PROCESSINGQUERY_REQUEST_TYPES.NLQ,
    "sql",
    "journey",
    "GUID"
  )}`;

  return {
    accounts: get(state, `appData.${queryKey}.data`) || [],
    accountsList: get(state, `appData.${dataKey}`) || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AsideSelectedAccounts);
