import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import LoadingSpinner from "../../common/LoadingSpinner";

export default class ContactUsForm extends React.Component {
  state = {
    formValues: {
      msgSubject: "",
      isUrgent: "false",
      userEmail: "",
      userPhone: "",
      msgBody: "",
    },
    errorStates: {},
    isUrgent: false,
    loading: false,
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loading ? (
          <div className="m-0 p-0 h-100 w-100">
            <div className="h-100 w-100 flex-fill">
              <LoadingSpinner
                title="Submitting your response..."
                color="text-info"
              />
            </div>
          </div>
        ) : (
          <Form onSubmit={this.onSubmit}>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faEdit} />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="msgSubject"
                  id="msgSubject"
                  placeholder="Enter subject here"
                  onChange={this.handleChange}
                  value={this.state.formValues.msgSubject || ""}
                  invalid={!!this.state.errorStates["msgSubject"]}
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>Subject or title</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <FormFeedback className="d-block">
                {this.state.errorStates["msgSubject"]}
              </FormFeedback>
            </FormGroup>
            <Row form>
              <Col sm={6}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FontAwesomeIcon icon={faEdit} />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="email"
                      name="userEmail"
                      id="userEmail"
                      placeholder="Enter email address here"
                      onChange={this.handleChange}
                      value={this.state.formValues.userEmail || ""}
                      invalid={!!this.state.errorStates["userEmail"]}
                    />
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Provide your email</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <FormFeedback className="d-block">
                    {this.state.errorStates["userEmail"]}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <FontAwesomeIcon icon={faEdit} />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="number"
                      name="userPhone"
                      id="userPhone"
                      placeholder="Enter phone number here"
                      onChange={this.handleChange}
                      value={this.state.formValues.userPhone || ""}
                      invalid={!!this.state.errorStates["userPhone"]}
                      style={{ resize: "none" }}
                    />
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Provide your phone number</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <FormFeedback className="d-block">
                    {this.state.errorStates["userPhone"]}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faEdit} />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  id="msgBody"
                  type="textarea"
                  className="form-control"
                  name="msgBody"
                  rows={6}
                  placeholder="Please provide detailed information about your reason to contact us.."
                  onChange={this.handleChange}
                  value={this.state.formValues.msgBody || ""}
                  invalid={!!this.state.errorStates["msgBody"]}
                />
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>Detailed message</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <FormFeedback className="d-block">
                {this.state.errorStates["msgBody"]}
              </FormFeedback>
            </FormGroup>
          </Form>
        )}
      </React.Fragment>
    );
  }

  onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (this.validateFormData()) {
      this.submitContactFormData();
    }
  };

  getContextData = () => {};

  handleChange = (event) => {
    const formValues = this.state.formValues;
    formValues[event.target.id] = event.target.value;

    const errorStates = this.state.errorStates;
    errorStates[event.target.id] = "";

    this.setState({ formValues });
  };

  validateFormData = () => {
    const errorStates = this.state.errorStates;
    var formValues = this.state.formValues;

    let validation = true;

    if (!formValues["msgSubject"] || !formValues["msgSubject"].trim().length) {
      errorStates["msgSubject"] = "Required";
      validation = false;
    }

    if (!formValues["msgBody"] || !formValues["msgBody"].trim().length) {
      errorStates["msgBody"] = "Required";
      validation = false;
    }

    this.setState({
      errorStates,
    });

    return validation;
  };

  submitContactFormData = async () => {
    let { OptionKey, pathname } = this.props;
    const updateData = this.state.formValues;
    if (this.props && this.props.isUrgent) {
      if (this.props.isUrgent === true) {
        updateData.isUrgent = "true";
      }
    }
    OptionKey.pageFilters["helpContext"].values = [];
    OptionKey.pageFilters["helpContext"].values.push(updateData);
    if (pathname) {
      OptionKey.pageFilters["pageContext"].values = [];
      OptionKey.pageFilters["pageContext"].values.push(pathname);
    }
    this.setState({
      loading: true,
    });
    await this.props.submitContactUsQuery(
      JSON.stringify(OptionKey),
      "ComponentRequest"
    );
    this.setState({
      loading: false,
    });
  };
}
