import { combineReducers } from "redux";
import apiErrors from "./apiErrors";
import apiLoading from "./apiLoading";
import appContext from "./appContext";
import appData from "./appData";
import loadZones from "./loadZones";
import keplerGlReducer from "kepler.gl/reducers";

const customKeplerGlReducer = keplerGlReducer.initialState({
  uiState: {
    currentModal: null,
    activeSidePanel: null,
  },
});

export default combineReducers({
  keplerGl: customKeplerGlReducer,
  appData,
  apiErrors,
  apiLoading,
  appContext,
  rightSideFilters: loadZones,
});
