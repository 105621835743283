import { API_URL } from "./constants";
import { clearSessionObject } from "./helpers";
import { customFetch } from "./webhooks.js";

function client(customConfig = {}) {
  const config = {
    ...customConfig,
  };
  return customFetch(`${API_URL}/api/core/queryProcessingIn/`, "POST", config)
    .then((response) => {
      if (response.resultCode === "SESSION_EXPIRED") {
        clearSessionObject();
        throw response.resultMessage;
      }
      if (response.resultCode === "FAILURE") {
        const error = {
          message: response.resultMessage,
          ...response,
        };
        return Promise.reject(error);
      }
      if (response.resultCode === "SUCCESS") {
        let pageId = config.OptionKey.pageId;
        if (pageId && pageId === "Asset") {
          return response;
        }
        if (response.resultData[0].resultPackage) {
          return response.resultData[0].resultPackage;
        } else {
          return response.resultData[0];
        }
      }
    })
    .catch((err) => {
      const error = {
        message: err.message,
        ...err,
      };
      return Promise.reject(error);
    });
}

export { client };
