import "mapbox-gl/dist/mapbox-gl.css";
import React, { useContext, useReducer, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "react-splitter-layout/lib/index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import store from "../state";
import AppHeader from "./common/appHeader/AppHeader";
import MenusContext from "./context";
import Login from "./pages/Login";
import RbStudio from "./pages/RbStudio";
import AppAside from "./partials/appLayout/AppAside";
import CurrentFilterContext from "./partials/FilterContext";
import SessionContext, { CurrentUser } from "./partials/SessionContext";
import menusReducer from "./reducer";
import AppRouter from "./routes";
import { clearSessionObject } from "../utils/helpers";

export default function App() {
  const [rightAsideOpen, setrightAsideOpen] = useState(false);
  const initialState = useContext(MenusContext);
  const [state, dispatch] = useReducer(menusReducer, initialState);
  const toggleAside = () => setrightAsideOpen(!rightAsideOpen);
  console.log("App.jsx deploy test 4");
  const renderOnContext = () => {
    return (
      <CurrentUser.Consumer>
        {({ currentUser }) => {
          if (!currentUser) {
            return <Login />;
          }
          return (
            <div
              style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* <AppHeader
                toggleRightSide={() => setrightAsideOpen(!rightAsideOpen)}
              />
              <AppAside
                className="main-aside"
                isOpen={rightAsideOpen}
                closeRightAsideOpen={toggleAside}
              /> */}
              {/* </AppHeader> */}
              <RbStudio>
                <AppRouter />
              </RbStudio>
            </div>
          );
        }}
      </CurrentUser.Consumer>
    );
  };

  return (
    <BrowserRouter>
      <Provider store={store}>
        <SessionContext>
          <CurrentFilterContext>
            <MenusContext.Provider value={{ state, dispatch }}>
              {renderOnContext()}
              <ToastContainer />
            </MenusContext.Provider>
          </CurrentFilterContext>
        </SessionContext>
      </Provider>
    </BrowserRouter>
  );
}
