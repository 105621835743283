import { faRedo, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import get from "lodash/get";
import map from "lodash/map";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { API_URL } from "../../../constants.js";
import { getSessionObject } from "../../../utils/helpers";
import { customFetch } from "../../../utils/webhooks.js";
import "../../common/SearchableCheckboxes.css";
class AsideSelectedAccount extends React.Component {
  static propTypes = {
    accounts: PropTypes.arrayOf(PropTypes.object),
    selectedCustomer: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
  };

  static defaultProps = {
    accounts: [],
    searchText: "",
  };

  componentDidMount() {
    this.fetchAccounts();
  }

  async fetchAccounts() {
    let body = {
      RequestType: "ComponentRequest",
      OptionKey: {
        pageId: "SearchBar",
        pageSubId: "PageLoad",
        requestType: "all",
        viewData: [
          {
            viewType: "data_collection",
          },
        ],
      },
      SessionObj: getSessionObject(),
    };
    let requestResult = await customFetch(
      `${API_URL}/api/core/queryProcessingIn/`,
      "POST",
      body
    );
    let accountList = get(
      requestResult,
      "resultData[0].resultPackage.values[0].accountsList"
    );
    this.setState({ accountList: accountList });
  }

  state = {
    showSearch: false,
  };

  render() {
    return <React.Fragment>{this.renderListGroupItems()}</React.Fragment>;
  }

  renderListGroupItems = () => {
    return (
      <React.Fragment>
        <Card
          className="m-0 searchableCheckboxes overflow-auto"
          style={{
            height: "90%",
          }}
        >
          <CardHeader>
            <span className="text-dark h5">{this.props.title}</span>
            <span className="card-header-actions d-flex justify-content-between">
              <FontAwesomeIcon
                icon={faSearch}
                className="ml-1"
                onClick={this.toggleSearchBar}
              />
              <FontAwesomeIcon
                icon={faRedo}
                className="ml-1"
                onClick={this.resetSelection}
              />
            </span>
          </CardHeader>
          {this.renderSearchBar()}
          <CardBody className="m-0 p-0 overflow-auto flex-fill">
            <ListGroup className="m-0 p-0">{this.renderAcounts()}</ListGroup>
          </CardBody>
        </Card>
        <div className="w-100 text-center mt-2">
          {this.state.buttonLoading ? (
            <button class="btn btn-primary" type="button" disabled>
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
          ) : (
            <Button
              color="primary"
              className="p-1"
              onClick={() => {
                this.setDefaultAcc();
              }}
            >
              Apply Account
            </Button>
          )}
        </div>
      </React.Fragment>
    );
  };

  async setDefaultAcc() {
    this.setState({ buttonLoading: true });
    let account = this.state.selectedAcc;
    let body = {
      mainQueryText: "",
      subQueryText: "",
      RequestType: "ComponentRequest",
      OptionKey: {
        pageId: "SetDefaultAcc",
        pageFilters: { set_account: account },
      },
      SessionGuid: "",
      SessionObj: getSessionObject(),
    };
    await customFetch(`${API_URL}/api/core/queryProcessingIn/`, "POST", body);
    window.location.reload(false);
    this.setState({ buttonLoading: false });
  }

  renderSearchBar = () => {
    if (this.state.showSearch) {
      return (
        <CardHeader>
          <input
            className="w-100 border-none"
            value={this.state.searchText}
            onChange={(e) =>
              this.setState({ searchText: e.currentTarget.value })
            }
          />
        </CardHeader>
      );
    }
  };

  renderAcounts = () => {
    return map(this.state.accountList, (valItem, i) => {
      const searchNormalizedText = this.state.searchText
        ? this.state.searchText.toLowerCase()
        : null;
      if (
        !searchNormalizedText ||
        valItem.toLowerCase().indexOf(searchNormalizedText) >= 0
      ) {
        return (
          <ListGroupItem
            key={`account_${valItem}`}
            style={{ cursor: "pointer" }}
            className={
              this.props.selectedCustomer === valItem
                ? "bg-success"
                : "bg-default"
            }
            onClick={() => {
              this.props.onSelect(valItem);
              this.setState({ selectedAcc: valItem });
            }}
          >
            {valItem}
          </ListGroupItem>
        );
      }
    });
  };

  toggleSearchBar = () => this.setState({ showSearch: !this.state.showSearch });

  resetSelection = () => {
    this.setState({
      searchText: "",
    });
  };
}

export default withRouter(AsideSelectedAccount);
