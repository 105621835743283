import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody } from "reactstrap";

export default class LoadingSpinner extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string,
  };

  render() {
    return (
      <Card className="h-100 mb-1 bordered border-info">
        <CardBody className="m-0 p-0 overflow-auto flex-fill">
          <div
            className="h-100 w-100 d-flex align-items-center justify-content-center"
            style={{ background: "rgba(255, 255, 255, 0.5)" }}
          >
            <Card className="bordered border-success">
              <CardBody className="text-center">
                <span className="h4">{this.props.title}</span>
                <br />
                <br />
                {this.props.children}
                {this.props.icon ? (
                  <div
                    style={{ marginLeft: 10 }}
                    className={this.props.color + " " + this.props.icon}
                  />
                ) : (
                  <div className={this.props.color + " spinner-border "} />
                )}
              </CardBody>
            </Card>
          </div>
        </CardBody>
      </Card>
    );
  }
}
