/* eslint-disable */
import set from "lodash/set";
import unset from "lodash/unset";
import { generateQueryProsKey } from "../../utils/helpers";
import {
  CORECONNECTION_API_FAILED,
  CORECONNECTION_API_FETCH,
  CORECONNECTION_API_SUCCESS,
  LOGIN_API_FAILED,
  LOGIN_API_FETCH,
  LOGIN_API_SUCCESS,
  PROSSQUERIN_API_FAILED,
  PROSSQUERIN_API_FETCH,
  PROSSQUERIN_API_SUCCESS,
} from "../actions/coreAPI";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case CORECONNECTION_API_FETCH:
    case CORECONNECTION_API_SUCCESS: {
      delete state["checkCoreConnection"];
      return state;
    }
    case CORECONNECTION_API_FAILED: {
      return {
        ...state,
        checkCoreConnection: action.payload,
      };
    }
    case PROSSQUERIN_API_SUCCESS:
    case PROSSQUERIN_API_FETCH: {
      const key = `processingQueryIn.${generateQueryProsKey(
        action.payload.queryString,
        action.payload.optionKey,
        action.payload.requestType,
        action.payload.subQueryText,
        action.payload.requestSpec,
        action.payload.sessionGuid
      )}`;

      return { ...unset(state, key) };
    }
    case PROSSQUERIN_API_FAILED: {
      const key = `processingQueryIn.${generateQueryProsKey(
        action.payload.queryString,
        action.payload.optionKey,
        action.payload.requestType,
        action.payload.subQueryText,
        action.payload.requestSpec,
        action.payload.sessionGuid
      )}`;
      return { error: action.payload.error };
    }
    case LOGIN_API_SUCCESS:
    case LOGIN_API_FETCH: {
      const key = `login.${generateQueryProsKey(
        action.payload.queryString,
        action.payload.optionKey,
        action.payload.requestType,
        action.payload.subQueryText,
        action.payload.requestSpec
      )}`;

      return { ...unset(state, key) };
    }
    case LOGIN_API_FAILED: {
      const key = `login.${generateQueryProsKey(
        action.payload.queryString,
        action.payload.optionKey,
        action.payload.requestType,
        action.payload.subQueryText,
        action.payload.requestSpec
      )}`;
      return { ...set(state, key, action.payload.error) };
    }
    default: {
      return state;
    }
  }
};
