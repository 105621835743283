/* eslint-disable array-callback-return */
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loading, Menu, Tooltip } from "element-react";
import "element-theme-default";
// import "element-theme-dark";
import { find } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { API_URL, menuIcons, menus } from "../../constants";
import { clearSessionObject, getSessionObject } from "../../utils/helpers";
import { customFetch } from "../../utils/webhooks.js";
import MenusContext from "../context";
import "./RbStudio.scss";

export default function RbStudio({ children }) {
  const { state, dispatch } = useContext(MenusContext);
  const [selectedMenu, setSelectedMenu] = useState(state.menus[0]);
  const [selectedSubMenuId, setselectedSubMenuId] = useState(1);
  const [isMenuLoading, toggleisMenuLoading] = useState(true);
  let location = useLocation();
  let history = useHistory();
  useEffect(() => {
    findAndSetSubmenus();
    isPageAllowed();
  }, [location.pathname, state.menus]);
  useEffect(() => {
    if (selectedMenu) {
      document.title = selectedMenu.name;
    }
  });
  useEffect(() => {
    fetchDashboards();
  }, []);
  const isPageAllowed = () => {
    let pathname = location.pathname;
    if (pathname === "/") {
      history.push("/master");
    }
    if (state.menus.length > 0 && pathname === "/master") {
      let x = find(state.menus, { link: "/master" });
      if (x === undefined) {
        history.push(state.menus[0].subMenu[0].link);
        // return
      }
    }
  };
  const fetchDashboards = async () => {
    let body = {
      RequestType: "ComponentRequest",
      OptionKey: { pageId: "Dashboards", pageSubId: "", requestType: "all" },
      SessionObj: getSessionObject(),
    };
    let res = await customFetch(
      `${API_URL}/api/core/queryProcessingIn/`,
      "POST",
      body
    );
    if (res.resultCode === "SESSION_EXPIRED") {
      clearSessionObject();
      toggleisMenuLoading(false);
      return;
    }
    if (res.resultCode === "FAILURE") {
      await dispatch({ type: "FETCH_MENU", payload: menus });
      toggleisMenuLoading(false);
      return;
    }
    await dispatch({ type: "FETCH_MENU", payload: res.resultData });
    toggleisMenuLoading(false);
  };
  const findAndSetSubmenus = async () => {
    // because there isn't menu hierarchy, this function is written to find submenus temporarily
    let menus = state.menus;
    let pathname = location.pathname;
    let menuFound = undefined;
    await (() => {
      for (let i = 0; i < menus.length; i++) {
        const menu = menus[i];
        if (pathname === menu.link) {
          menuFound = menu;
          return;
        }
      }
    })();
    if (!menuFound) {
      let subMenuFound = undefined;
      await (() => {
        for (let i = 0; i < menus.length; i++) {
          const menu = menus[i];
          let submenus = menu.subMenu;
          for (let j = 0; j < submenus.length; j++) {
            const submenu = submenus[j];
            if (pathname === submenu.link) {
              setselectedSubMenuId(submenu.id);
              setSelectedMenu(menu);
              subMenuFound = menu;
              return;
            }
          }
        }
      })();
      if (!subMenuFound) {
        if (menus.length > 0) {
          let coldChainMenu = find(menus, { title: "Cold Chain" });
          setSelectedMenu(coldChainMenu);
        }
      }
    } else {
      setselectedSubMenuId(menuFound.subMenu[0].id);
      setSelectedMenu(menuFound);
      if (menuFound.subMenu[0].isGroup) {
        history.push(menuFound.subMenu[0].groupItems[0].link);
        return;
      }
      history.push(menuFound.subMenu[0].link);
    }
  };
  const onOpen = () => {};
  const onClose = () => {};
  const handleMenuItemClick = (s) => {
    if (selectedMenu.subMenu[0].isGroup) {
      selectedMenu.subMenu.map((subMenu) => {
        let selectedSubMenu = find(subMenu.groupItems, { id: s });
        if (selectedSubMenu) {
          history.push(selectedSubMenu.link);
          setselectedSubMenuId(s);
          return;
        }
      });
      return;
    }
    let selectedSubMenu = find(selectedMenu.subMenu, { id: s });
    if (selectedSubMenu) {
      history.push(selectedSubMenu.link);
      setselectedSubMenuId(s);
    }
  };
  return (
    <div className="h-100 w-100 d-flex">
      <Loading
        loading={isMenuLoading}
        style={{
          display: state.isFullscreen ? "none" : "block",
        }}
      >
        <div
          className="rb-aside"
          style={{
            display: "flex",
            flexDirection: "column",
            width: state.isSideBarHidden ? 55 : "215px",
          }}
        >
          <Menu
            theme={state.isDark ? "dark" : "light"}
            style={{ flex: 1 }}
            defaultActive={selectedSubMenuId}
            onOpen={onOpen}
            onClose={onClose}
            onSelect={(idx) => handleMenuItemClick(idx)}
          >
            {selectedMenu
              ? selectedMenu.subMenu.map((s, i) => {
                  if (s.isGroup) {
                    return (
                      <Menu.ItemGroup
                        title={state.isSideBarHidden ? null : s.label}
                        key={i}
                      >
                        {s.groupItems.map((menuItem, k) => (
                          <Menu.Item key={k} index={menuItem.id}>
                            <FontAwesomeIcon
                              icon={
                                find(menuIcons, { label: menuItem.label })
                                  ? find(menuIcons, { label: menuItem.label })
                                      .icon
                                  : faChartLine
                              }
                            />
                            {state.isSideBarHidden ? (
                              <></>
                            ) : (
                              <span style={{ marginLeft: 10 }}>
                                {menuItem.label}
                              </span>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.ItemGroup>
                    );
                  }
                  return (
                    <Tooltip
                      disabled={!state.isSideBarHidden}
                      className="item"
                      effect="dark"
                      content={s.label}
                      placement="right"
                      key={i}
                    >
                      <Menu.Item key={i} index={s.id}>
                        <FontAwesomeIcon
                          icon={
                            find(menuIcons, { label: s.label })
                              ? find(menuIcons, { label: s.label }).icon
                              : faChartLine
                          }
                        />
                        {state.isSideBarHidden ? (
                          <></>
                        ) : (
                          <span style={{ marginLeft: 10 }}>{s.label}</span>
                        )}
                      </Menu.Item>
                    </Tooltip>
                  );
                })
              : null}
          </Menu>
          <div
            className="p-2"
            style={{ display: state.isSideBarHidden ? "none" : "block" }}
          >
            <small style={{ color: "grey" }}>v2.2</small>
          </div>
        </div>
      </Loading>
      <div className="rb-container h-100">
        <div
          className="tab-container"
          style={{
            width: state.isSideBarHidden
              ? "100%"
              : state.isFullscreen
              ? "100%"
              : `calc(100vw - 220px)`,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
